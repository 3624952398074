import React, { useState, useEffect } from "react";
import Title from "../../newLayout/Title";
import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Input,
  InputLabel,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  OutlinedInput,
  Popover,
  styled,
  TableCell,
  tableCellClasses,
  Typography,
} from "@mui/material";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import SuspendIcon from '@mui/icons-material/Cancel';
import ActivateIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from "react-router-dom";
import {
  EMPLOYEE_DATA,
  GET_CHECK_IN_QRCODE_DATA,
} from "../../../graphql/queries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
// import QrCodeIcon from "@mui/icons-material/QrCode";
import swal from "sweetalert";
import { AUTH_ID } from "../../Authentication/constant";
import {
  DELETE_EMPLOYEE,
  CHANGE_EMPLOYEE_STATUS,
  UPDATE_OR_CREATE_EMPLOYEE,
} from "../../../graphql/mutations";
import PopupState, {
  bindPopover,
  bindToggle,
  bindTrigger,
} from "material-ui-popup-state";
import QRCode from "react-qr-code";
// import ReactToPdf from "react-to-pdf";
import CircularProgress from "@mui/material/CircularProgress";
import BasicSnackBar from "../BasicSnackBar";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(() => ({
  textAlign: "center",
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 700,
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

export default function EmployeeList() {
  const ref = React.createRef();
  const {t} = useTranslation("global");
  const initialValues = {
    email: "",
    name: "",
    contact: "",
    address: "",
    // password: "",
    wage: "",
    role_id: 4,
  };
  const navigate = useNavigate();
  const [employeeList, setEmployeeList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [newEmployee, setNewEmployee] = useState(initialValues);
  const [modalOpen, setmodalOpen] = useState(false);
  const [checkInQRcodeData, setCheckInQRCodeData] = useState("");
  const [modalOpenQRCode, setmodalOpenQRCode] = useState(false);
  //------------------------Sucess Alert State......
  const [errors, setErrors] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  ///////////>>>>>>>Query: Get Employee Data...................
  const { data, loading } = useQuery(EMPLOYEE_DATA, {
    variables: { parent_id: AUTH_ID },
    fetchPolicy: 'no-cache'
  });

  const handleCloseQRCode = () => setmodalOpenQRCode(false);
  // useLazyQuery for employee qrcode data.
  const [{ data: checkInData }] = useLazyQuery(GET_CHECK_IN_QRCODE_DATA);

  /////////=====Mutation: addEmployee..........>
  const [createEmployee, { data: empData, loading: empLoading }] = useMutation(UPDATE_OR_CREATE_EMPLOYEE,{
    onError:(()=>{
      console.log("error occurred");
    })
  });
  
  const onValueChange = (e) => {
    let data = { ...newEmployee };
    let name = e.target.name;
    let val = e.target.value;
    if (
      name === "name" ||
      name === "email" ||
      name === "contact" ||
      name === "address" ||
      name === "wage" ||
      name === "role_id"
    ) {
      data = { ...data, [name]: val };
    }
    setNewEmployee(data);
  };

  useEffect(() => {
    try {
      setEmployeeList(data.employees);
      setCheckInQRCodeData("cn=" + checkInData.employee.company.name + "&ci=");
    } catch (error) {
      console.log(error.message);
    }
  }, [data, checkInData]);

  useEffect(() => {
    if (empData) {
      setEmployeeList(prevEmployeeList => [
        empData.updateOrCreateEmployee,
        ...prevEmployeeList,
      ]);
    }
  }, [empData]);

  const handleClick = async () => {
    if (
      // newEmployee.email &&
      newEmployee.name
      // newEmployee.contact &&
      // newEmployee.address &&
      // newEmployee.wage
    ) {
        const response = await createEmployee({
          variables: {
            email: newEmployee.email,
            name: newEmployee.name,
            contact: newEmployee.contact,
            address: newEmployee.address,
            wage: newEmployee.wage ? parseFloat(newEmployee.wage) : 0.00,
            role_id: 4,
            parent_id: AUTH_ID,
          },
        });

        if (response && response.data) {
          // Mutation was successful
          setmodalOpen(false);
          setNewEmployee("");
          setOpenAlert(true);
          setErrors(false);

        } else if (response && response.errors) {
          const graphQLErrors = response.errors.graphQLErrors;
          if (graphQLErrors) {
            const errorMessages = [];
            graphQLErrors.forEach((error) => {
              const validationErrors = error.extensions.validation;
              if (validationErrors) {
                Object.keys(validationErrors).forEach((fieldName) => {
                  const errorMessage = validationErrors[fieldName][0];
                  errorMessages.push(errorMessage);
                });
              }
            });
      
            setErrors(errorMessages);
          }
        }
    } else {
      setErrors(["The name is required"]);
      setmodalOpen(true);
    }
  };
  // Delete Employee Data.....
  const [deleteEmployee] = useMutation(DELETE_EMPLOYEE);
  const [changeEmployeeStatus] = useMutation(CHANGE_EMPLOYEE_STATUS);

  const handleDeleteClick = (id) => {
    swal({
      title: t('pop_ups.are_you_sure'),
      text: t('pop_ups.employee_delete_confirm'),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteEmployee({
          variables: {
            id: id,
          },
          refetchQueries: [
            {
              query: EMPLOYEE_DATA,
              variables: { parent_id: AUTH_ID },
            },
          ],
        })
          .then((response) => {
            if(response.data.deleteUser.success){
              const indexToDelete = employeeList.findIndex((employee) => employee.id === id);
              if (indexToDelete !== -1) {
                const updatedEmployeeData = [...employeeList];
                updatedEmployeeData.splice(indexToDelete, 1);
                setEmployeeList(updatedEmployeeData);
              }
              swal(t('pop_ups.employee_deleted'), {
                icon: "success",
              });
            }else{
              swal(response.data.deleteUser.message, {
                icon: "error",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            swal(t('pop_ups.error_occurred'), {
              icon: "error",
            });
          });
      } else {
        swal(t('pop_ups.employee_is_safe'));
      }
    });
  };

  const handleChangeUserStatusClick = (id, newStatus) => {

    let confirmation_msg = "";
    let confirmed_msg = "";
    if(newStatus === "suspended"){
      confirmation_msg = t('pop_ups.employee_suspend_confirm');
      confirmed_msg = t('pop_ups.employee_suspended');
    }else{
      confirmation_msg = t('pop_ups.employee_activate_confirm');
      confirmed_msg = t('pop_ups.employee_activated');
    }

    swal({
      title: t('pop_ups.are_you_sure'),
      text: confirmation_msg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        changeEmployeeStatus({
          variables: {
            id: id,
            newStatus: newStatus
          }
        })
          .then((response) => {
            if(response.data.changeEmployeeStatus){

              const updatedEmployeesData = employeeList.map(employee => {
                if (employee.id === id) {
                  return { ...employee, status: response.data.changeEmployeeStatus.status };
                } else {
                  return employee;
                }
              });

              setEmployeeList(updatedEmployeesData);

              swal(confirmed_msg, {
                icon: "success",
              });
            }else{
              swal(t('pop_ups.error_occurred'), {
                icon: "error",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            swal(t('pop_ups.error_occurred'), {
              icon: "error",
            });
          });
      } else {
        swal(t('pop_ups.employee_is_safe'));
      }
    });
  };

  const handleOpen = () => setmodalOpen(true);
  const handleClose = () => setmodalOpen(false);

  const columns = [
    {
      field: "name",
      headerName: t('general.name'),
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "email",
      headerName: t('general.email'),
      minWidth: 230,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "contact",
      headerName: t('general.contact'),
      minWidth: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "address",
      headerName: t('general.address'),
      minWidth: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "wage",
      headerName: t('employees.wage_hr'),
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => {
        
        if(params.row.company && params.row.company.currency){
          const symbol = params.row.company.currency.symbol;
          return (
            `${symbol}${params.row.wage.toFixed(2)}`
          );
        }else{
          return (
            `$${params.row.wage.toFixed(2)}`
          );
        }
        
      },
    },
    {
      field: "status",
      headerName: t('general.status'),
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => {
        const color = params.row.status === "ACTIVE" ? "success" : "error";
        return (
          <Chip label={params.row.status} color={color} variant="outlined" />
        );
      },
    },
    {
      field: "actions",
      headerName: t('general.actions'),
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => (
        <StyledTableCell>
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <div>
                {/* Action in Table Cell */}
                <MoreVertIcon
                  sx={{ color: "gray", fontSize: "28px" }}
                  {...bindTrigger(popupState)}
                />
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Box sx={{ display: "block" }}>
                    <Link
                      onClick={() => {
                        navigate(
                          `/dashboard/companyDashboard/employees/updateEmployee/${params.id}`
                        );
                      }}
                      style={{
                        textDecoration: "none",
                        color: "gray",
                        hover: {
                          bgcolor: "gray",
                        },
                      }}
                    >
                      <ListItemButton {...bindToggle(popupState)}>
                        <ListItemIcon>
                          <EditIcon />
                        </ListItemIcon>
                        <ListItemText primary={ t('employees.update_employee') } />
                      </ListItemButton>
                    </Link>
                    <Divider width="100%" flexItem />
                    <Link
                      onClick={ () => handleDeleteClick(params.id) }
                      style={{
                        textDecoration: "none",
                        color: "gray",
                      }}
                    >
                      <ListItemButton {...bindToggle(popupState)}>
                        <ListItemIcon>
                          <DeleteIcon color="error" />
                        </ListItemIcon>
                        <ListItemText primary={ t('employees.delete_employee') } />
                      </ListItemButton>
                    </Link>

                    {/* will render if the employee is active */}
                    {
                      params.row.status === "ACTIVE" ? 
                        (<>
                          <Divider width="100%" flexItem />
                          <Link
                            onClick={ () => handleChangeUserStatusClick(params.id, 'suspended') }
                            style={{
                              textDecoration: "none",
                              color: "gray",
                            }}
                          >
                            <ListItemButton {...bindToggle(popupState)}>
                              <ListItemIcon>
                                <SuspendIcon color="disabled" />
                              </ListItemIcon>
                              <ListItemText primary={ t('employees.suspend_employee') } />
                            </ListItemButton>
                          </Link> 
                        </>) : ""
                    }

                    {/* will render if the employee is suspended */}
                    {
                      params.row.status === "SUSPEND" ? 
                      (
                        <>
                        <Divider width="100%" flexItem />
                        <Link
                          onClick={ () => handleChangeUserStatusClick(params.id, 'activated') }
                          style={{
                            textDecoration: "none",
                            color: "gray",
                          }}
                        >
                          <ListItemButton {...bindToggle(popupState)}>
                            <ListItemIcon>
                              <ActivateIcon color="success" />
                            </ListItemIcon>
                            <ListItemText primary={ t('employees.activate_employee') } />
                          </ListItemButton>
                        </Link>
                      </>
                      ) : ""
                    }
                 
                  </Box>
                </Popover>
              </div>
            )}
          </PopupState>
        </StyledTableCell>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Title>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            marginBottom: "20px",
          }}
        >
          <Button
            onClick={handleOpen}
            variant="contained"
            sx={{ bgcolor: "#F16101", ":hover": { bgcolor: "#F16101" } }}
          >
            {/* Add Employee */}
            { t("pending_to_approve.add_employee") }
          </Button>
        </Box>
      </Title>
      {/* DataGrid Table */}
      <Box
        sx={{
          height: "80vh",
          width: "100%",
          "& .super-app-theme--header": {
            fontWeight: "700",
            fontSize: 18,
          },
          "& .super-app-theme--cell": {
            fontSize: 18,
          },
        }}
      >
        <DataGrid
          loading={loading}
          columns={columns}
          rows={employeeList}
          rowHeight={60}
          getRowId={(row) => row.id}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 30]}
          localeText={{
            toolbarExport: t('data_grid.export'),
            toolbarExportCSV : t('data_grid.toolbar_export_csv'),
            toolbarExportPrint : t('data_grid.toolbar_export_print'),
            toolbarFilters : t('data_grid.toolbar_filters'),
            toolbarColumns : t('data_grid.toolbar_columns'),
            columnMenuSortAsc : t('data_grid.column_menu_sort_asc'),
            columnMenuSortDesc: t('data_grid.column_menu_sort_desc'),
            columnMenuHideColumn : t('data_grid.column_menu_hide_column'),
            columnMenuManageColumns : t('data_grid.column_menu_manage_columns'),
            toolbarDensity: t('data_grid.toolbar_density_label'),
            toolbarDensityLabel: t('data_grid.toolbar_density_label'),
            toolbarDensityCompact: t('data_grid.toolbar_density_compact'),
            toolbarDensityStandard: t('data_grid.toolbar_density_standard'),
            toolbarDensityComfortable: t('data_grid.toolbar_density_comfortable'),
          }}
          slotProps={{
            pagination: {
              labelRowsPerPage: t('data_grid.rows_per_page_options'),
              labelDisplayedRows : ({ from, to, count }) => {
                    const countText = count !== -1 ? count : `${t('general.more_than')} ${to}`;
                    return `${from}–${to} ${t('general.of')} ${countText}`;
                } 
            }
          }}
        />
      </Box>
      {/* add Emplloyee Modal. */}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box>
            <Typography variant="h6" textAlign="center">
             { t('employees.edit_employee_detail') }
            </Typography>
            <FormControl fullWidth>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="name">
                { t('general.name') }
              </InputLabel>
              <OutlinedInput
                id="name"
                sx={{ m: 1 }}
                fullWidth
                name="name"
                value={newEmployee.name}
                onChange={(e) => onValueChange(e)}
                label="name"
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="email">
              { t('general.email') }
              </InputLabel>
              <OutlinedInput
                id="email"
                sx={{ m: 1 }}
                fullWidth
                name="email"
                value={newEmployee.email}
                onChange={(e) => onValueChange(e)}
                label="email"
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="wage">
                { t('employees.wage_hr') }
              </InputLabel>
              <OutlinedInput
                type="wage"
                id="wage"
                sx={{ m: 1 }}
                fullWidth
                name="wage"
                value={newEmployee.wage}
                onChange={(e) => onValueChange(e)}
                label="wage / hr"
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="contact">
              { t('general.contact') }
              </InputLabel>
              <OutlinedInput
                id="contact"
                sx={{ m: 1 }}
                fullWidth
                name="contact"
                value={newEmployee.contact}
                onChange={(e) => onValueChange(e)}
                label="contact"
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="address">
              { t('general.address') }
              </InputLabel>
              <OutlinedInput
                id="address"
                sx={{ m: 1 }}
                fullWidth
                name="address"
                value={newEmployee.address}
                onChange={(e) => onValueChange(e)}
                label="address"
              />
            </FormControl>
            <Input type="hidden" name="role_id" value={newEmployee.role_id} />

            {errors?.length > 0 && (
              <div>
                {errors.map((errorMessage, index) => (
                  <Alert key={index} severity="error">
                    {errorMessage}
                  </Alert>
                ))}
              </div>
            )}
          </Box>
          <Box sx={{ mt: 3, mb: 2, textAlign: "center" }}>
            <Button
              onClick={() => {
                handleClose();
                setNewEmployee("");
                setErrors(false);
              }}
              variant="contained"
              sx={{
                minWidth: "150px",
                p: 1,
                m: 1,
                bgcolor: "#ff9800",
                ":hover": { bgcolor: "#ff9800" },
              }}
            >
              { t('general.cancel') }
            </Button>
            <Button
              sx={{
                minWidth: "150px",
                p: 1,
                m: 1,
                bgcolor: "#F16101",
                ":hover": { bgcolor: "#F16101" },
              }}
              variant="contained"
              onClick={() => handleClick()}
              disabled={empLoading}
            >
              {empLoading ? (
                <CircularProgress size={24} color="primary" />
              ) : (
                 t('general.add') 
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* Success Alert Message */}
      <BasicSnackBar
        open={openAlert}
        onClose={handleAlertClose}
        severity="success"
        message={ t('pop_ups.employee_added') }
      />
      {/* Generate QRCode Modal. */}
      <Modal
        open={modalOpenQRCode}
        onClose={handleCloseQRCode}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box ref={ref}>
            <Typography
              variant="h6"
              align="center"
              sx={{ marginBottom: "20px" }}
            >
              QR Code
            </Typography>
            <div
              style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: 150,
                width: "100%",
              }}
            >
              {checkInQRcodeData ? (
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={checkInQRcodeData}
                  viewBox={`0 0 256 256`}
                />
              ) : (
                t("general.generating_qrcode")
              )}
            </div>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
