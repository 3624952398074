import React, { useState } from 'react';
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem
  } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UPDATE_USER_LANGUAGE } from '../graphql/mutations';
import { AUTH_ID } from './Authentication/constant';

function LanguageSelector() {
  const [t, i18n] = useTranslation('global');
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('lang') || 'en'
  );

  const [updateUserLanguage] = useMutation(UPDATE_USER_LANGUAGE);

  const handleChangeLanguage = async (lang) => {
    setSelectedLanguage(lang);
    try {
      localStorage.setItem('lang', lang);
      const response = await updateUserLanguage({
        variables: { id: AUTH_ID, language: lang },
      });
      console.log(response);
    } catch (error) {
      console.error(error);
    }

    //set the i18n object
    i18n.changeLanguage(lang);
  };

  return (
      <FormControl sx={{ m: 1, minWidth: 150 }}>
      <InputLabel
        htmlFor="select-lang"
        sx={{
          color: 'white',
          '&.Mui-focused': {
            color: 'white',
          },
        }}
      >
        {t('choose_language')}
      </InputLabel>
      <Select
        labelId="select-lang"
        autoWidth
        label="Select Language"
        value={selectedLanguage}
        onChange={(e) => handleChangeLanguage(e.target.value)}
        sx={{
          color: 'white',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
            },
          },
        }}
        inputProps={{
          sx: {
            color: 'white',
          },
        }}
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="de">German</MenuItem>
        <MenuItem value="es">Spanish</MenuItem>
        <MenuItem value="th">Thai</MenuItem>
        <MenuItem value="ar">Arabic</MenuItem>
      </Select>
    </FormControl>
  );
}

export default LanguageSelector;
