import React, { useLayoutEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { MainListItems } from "./listItem";
import { Route, Routes, useNavigate } from "react-router-dom";
import EditCompany from "../admin/EditCompany";
// import ViewDetail from "../admin/ViewDetail";
import EmployeeList from "../company/employee/EmployeeList";
import Customers from "../company/customer/Customers";
import Schedule from "../company/schedule/Schedule";
import AdminDashBoard from "../admin/AdminDashBoard";
import CompanyDashboard from "../company/CompanyDashBoard";
import AdminProtected from "./protectedRoutes/AdminProtected";
import { AUTH_TOKEN, ROLE_ID, USER_ID } from "../Authentication/constant";
import CompanyProtected from "./protectedRoutes/CompanyProtected";
import Companies from "./Companies";
import UpdateCustomer from "../company/customer/UpdateCustomer";
import UpdateEmployee from "../company/employee/UpdateEmployee";
import UpdateSchedule from "../company/schedule/UpdateSchedule";
import CheckInCheckOutReport from "../company/reports/CheckInCheckOutReport";
import { ListItemIcon, Menu, MenuItem } from "@mui/material";
// import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import AdminProfile from "./pages/AdminProfile";
import CompanyProfile from "./pages/CompanyProfile";
import Profile from "./pages/profile";
import { useQuery } from "@apollo/client";
import { PROFILE_DATA } from "../../graphql/queries";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import CustomerDetail from "../company/customer/CustomerDetail";
import logoImage from "../../assets/logo.png";
import SummaryReport from "../company/reports/SummaryReport";
import PendingCheckouts from "../company/PendingCheckouts";
import CheckoutDetail from "../company/checkout/CheckoutDetail";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector";
import NotFound from "../Authentication/NotFound";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();
const Dashboard = () => {
  const navigate = useNavigate();
  const [roleId, setRoleId] = useState("");
  const [open, setOpen] = useState(true);
  const [t, i18n] = useTranslation('global');
  function toggleDrawer() {
    setOpen(!open);
  }
  //---------------Account Button States.
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchorEl = Boolean(anchorEl);
  const handleAnchorElClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAnchorElClose = () => {
    setAnchorEl(null);
  };

  const [user, setUser] = useState(null); 
  const { data } = useQuery(PROFILE_DATA);

  useEffect(() => {
      try {
        setUser(data.me);
      } catch (error) {
        console.log(error.message);
      }
  }, [data]); 

  const handleLogout = () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(ROLE_ID);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem('lang');
    navigate(`/`);
  };
  useLayoutEffect(() => {
    setRoleId(localStorage.getItem(ROLE_ID));
  }, []);
  
  //---------------Avatar Name Function
  function stringAvatar(name) {
    return {
      sx: {
        // color: 'success',
      },
      children: `${name.split(" ")[0][0]}`,
    };
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" sx={{ bgcolor: "#F16101" }} open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, color: "#ffffff" }}
            >
              <span>CheckIn-CheckOut</span>
            </Typography>


            {/* change language options */}
            <LanguageSelector />

            <IconButton
              onClick={handleAnchorElClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={openAnchorEl ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openAnchorEl ? "true" : undefined}
            >
              {user ? (
                <Avatar
                  {...stringAvatar(user ? user.name : "")}
                  sx={{ width: 40, height: 40, fontSize: 24 }}
                />
              ) : (
                <CircularProgress size={28} color="warning" />
              )}
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: [1],
            }}
          >
            {/* import the avatar from public folder */}
            <img
              src={logoImage}
              alt="logo"
              height="25"
              style={{ marginRight: "10px" }}
            />
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <MainListItems roleId={roleId} t={t} i18n={i18n}/>
            <Divider sx={{ my: 1 }} />
            {/* {secondaryListItems} */}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          {/* Routes */}
          <Grid>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                minHeight: "calc(100vh - 64px)",
              }}
            >
              <Routes>
                {/* make Admin routes protucted */}
                <Route
                  path="adminDashboard/*"
                  element={
                    <AdminProtected roleId={roleId}>
                      <Routes>
                        <Route index element={<AdminDashBoard />} />
                        <Route path="profile" element={<AdminProfile />} />
                        <Route exact path="companies" element={<Companies />} />
                        <Route
                          exact
                          path="editCompany/:id"
                          element={<EditCompany />}
                        />
                      </Routes>
                    </AdminProtected>
                  }
                />
                {/* make Company routes protucted */}
                <Route
                  path="companyDashboard/*"
                  element={
                    <CompanyProtected roleId={roleId}>
                      <Routes>
                        <Route
                          index
                          element={<CompanyDashboard loggedInUser={user} />}
                        />
                        <Route path="profile" element={<CompanyProfile />} />
                        <Route path="checkoutdetail/:id" element={<CheckoutDetail />} />
                        <Route path="customers/">
                          <Route index element={<Customers />} />
                          <Route
                            exact
                            path="updateCustomer/:id"
                            element={<UpdateCustomer />}
                          />
                          <Route
                            exact
                            path="customerDetail/:id"
                            element={<CustomerDetail />}
                          />
                        </Route>
                        <Route path="employees/">
                          <Route index element={<EmployeeList />} />
                          <Route
                            exact
                            path="updateEmployee/:id"
                            element={<UpdateEmployee />}
                          />
                          {/* <Route
                            exact
                            path="viewEmployeeDetail/:id"
                            element={<ViewEmployeeDetail />}
                          /> */}
                        </Route>
                        <Route>
                          <Route path="schedule/:id" element={<Schedule />} />
                          <Route
                            path="customer-detail/:id"
                            element={<Schedule loggedInUser={user} />}
                          />
                          <Route
                            exact
                            path="schedule/updateSchedule/:id"
                            element={<UpdateSchedule />}
                          />
                        </Route>
                        <Route path="pending-checkouts">
                          <Route index element={<PendingCheckouts />} />
                        </Route>
                        <Route path="checkincheckout/">
                          <Route index element={<CheckInCheckOutReport />} />
                        </Route>
                        <Route path="summary/">
                          <Route index element={<SummaryReport />} />
                        </Route>
                      </Routes>
                    </CompanyProtected>
                  }
                />
                {/* Add a catch-all route for unmatched URLs */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Paper>
          </Grid>
        </Box>
        {/* Account */}
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={openAnchorEl}
          onClose={handleAnchorElClose}
          onClick={handleAnchorElClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >

          {/* profile component */}
          <Profile />
          {/* <MenuItem onClick={handleAnchorElClose}>
            <Avatar /> My account
          </MenuItem> */}
          <Divider />
          {/* <MenuItem onClick={handleAnchorElClose}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem> */}
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            { t('general.logout') }
          </MenuItem>
        </Menu>
        {/* ------------------- */}
      </Box>
    </ThemeProvider>
  );
};
export default Dashboard;
// export default function Dashboard() {
//   return <DashboardContent />;
// }
