import { useNavigate } from "react-router-dom";

function CompanyProtected({ roleId, children}) {
    const navigate = useNavigate();

    if ( roleId === '2') {
      return children;
    }
    return navigate("/");
}

export default CompanyProtected