import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AUTH_ID } from "../../Authentication/constant";

function ProtectRoutes({ children }) {
  const roleId = localStorage.getItem("role_id");
  const navigate = useNavigate();

  useEffect(() => {
    if (!(AUTH_ID && (roleId === '1' || roleId === '2'))) {
      navigate("/");
    }
  }, [navigate, roleId]);

  if (!(AUTH_ID && (roleId === '1' || roleId === '2'))) {
    return null;
  }

  return children;
}

export default ProtectRoutes;
