import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  styled,
  TableCell,
  tableCellClasses,
  Typography,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import {
  GET_CUSTOMER,
  GET_SCHEDULES_PER_CUSTOMER,
} from "../../../graphql/queries";
import { useQuery } from "@apollo/client";
import { AUTH_ID } from "../../Authentication/constant";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import PopupState, {
  bindPopover,
  bindTrigger,
  bindToggle,
} from "material-ui-popup-state";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate, useParams } from "react-router-dom";
import Title from "../../newLayout/Title";
import QRCodeBox from "../../QRCodeBox";
import DownloadMenu from "../../DownloadMenu";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(() => ({
  textAlign: "center",
  borderBottom: "none",
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 700,
  },
}));
// const rows = [
//   {
//     id: 1,
//     employees: [
//       {
//         id: 1,
//         name: "Umair",
//       },
//       {
//         id: 2,
//         name: "Hassan",
//       },
//     ],
//     days: [
//       {
//         id: 1,
//         name: "monday",
//       },
//       {
//         id: 2,
//         name: "Tusday",
//       },
//     ],
//     start_time: "2023-01-04 11:30:00",
//     end_time: "2023-01-04 15:30:00",
//   },
//   {
//     id: 2,
//     employees: [
//       {
//         id: 1,
//         name: "Azhar",
//       },
//       {
//         id: 2,
//         name: "Usama",
//       },
//     ],
//     days: [
//       {
//         id: 1,
//         name: "Saturday",
//       },
//       {
//         id: 2,
//         name: "Sunday",
//       },
//     ],
//     start_time: "2023-01-04 10:40:00",
//     end_time: "2023-01-04 12:40:00",
//   },
// ];
const Schedule = ({ loggedInUser }) => {
  const navigate = useNavigate();
  const qrCodeRef = useRef();
  const {t} = useTranslation('global');
  // id get from schedule detail of customer components...
  const { id } = useParams();
  const [customerData, setCustomerData] = useState("");

  const [scheduleData, setScheduleData] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  // State for QRCode
  const [checkInQRcodeData, setCheckInQRCodeData] = useState("");
  const [dataUrl, setDataUrl] = useState('');

  const handleDataUrlChange = (url) => {
    setDataUrl(url); // Receive dataUrl from the QRCodeBox component
  };

  const { data: custData, loading: custLoading } = useQuery(GET_CUSTOMER, {
    variables: { id: id },
    fetchPolicy:"no-cache"
  });

  //----------------Get Schedule----------
  const { data: getScheduleQuery, loading } = useQuery(
    GET_SCHEDULES_PER_CUSTOMER,
    {
      variables: { parent_id: AUTH_ID, customer_id: id },
      fetchPolicy:"no-cache"
    }
  );

  useEffect(() => {
    if (loggedInUser && custData && custData.customer) {
      setCheckInQRCodeData(
        "wcId=" +
          loggedInUser.id +
          "&wcName=" +
          loggedInUser.name +
          "&ccId=" +
          custData.customer.id +
          "&ccName=" +
          custData.customer.name
      );
    }

    console.log('checkInQRcodeData',checkInQRcodeData);
    try {
      setCustomerData(custData.customer);
      setScheduleData(getScheduleQuery.schedules);
    } catch (error) {
      console.log(error.message);
    }
  }, [getScheduleQuery, custData, loggedInUser]);

  const columns = [
    {
      field: "employees",
      headerName:  t('employees.employees'),
      minWidth: 150,
      flex: 1.5,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => (
        <List>
          {params.row.employees?.map((employee, index) => {
            return (
              <ListItem key={index} sx={{ fontSize: 18, padding: 0 }}>
                {employee.name}
              </ListItem>
            );
          })}
        </List>
      ),
    },
    {
      field: "days",
      headerName: t('days.days'),
      minWidth: 150,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => (
        <List>
          {params.row.days?.map((day, index) => {
            return (
              <ListItem key={index} sx={{ fontSize: 18, padding: 0 }}>
                {day.name}
              </ListItem>
            );
          })}
        </List>
      ),
    },
    {
      field: "start_time",
      headerName: t('general.start_time'),
      minWidth: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      valueGetter: (params) => {
        let name = params.row.start_time.toString();
        let value = name.slice(10, 16);
        const number = moment(value, ["HH.mm"]).format("hh:mm a");
        return number;
      },
    },
    {
      field: "end_time",
      headerName: t('general.end_time'),
      minWidth: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      valueGetter: (params) => {
        let name = params.row.end_time.toString();
        let value = name.slice(10, 16);
        const number = moment(value, ["HH.mm"]).format("hh:mm a");
        return number;
      },
    },
    {
      field: "actions",
      headerName: t('general.actions'),
      minWidth: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => {
        return (
          <StyledTableCell>
            <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState) => (
                <div>
                  {/* Action in Table Cell */}
                  <MoreVertIcon
                    sx={{ color: "gray", fontSize: "28px" }}
                    {...bindTrigger(popupState)}
                  />
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Box sx={{ display: "block" }}>
                      <Link
                        to={`/dashboard/companyDashboard/schedule/updateSchedule/${params.id}`}
                        style={{
                          textDecoration: "none",
                          color: "gray",
                          hover: {
                            bgcolor: "gray",
                          },
                        }}
                      >
                        <ListItemButton {...bindToggle(popupState)}>
                          <ListItemIcon>
                            <EditIcon />
                          </ListItemIcon>
                          <ListItemText primary={ t('schedules.update_schedule') } />
                        </ListItemButton>
                      </Link>
                    </Box>
                  </Popover>
                </div>
              )}
            </PopupState>
          </StyledTableCell>
        );
      },
    },
  ];

  return (
    <Box height="100%" sx={{ paddingY: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <ArrowBackIcon
          sx={{ color: "gray", marginBottom: "15px", fontSize: "28px" }}
          onClick={() => navigate("/dashboard/companyDashboard/customers")}
        />
        <Button
          variant="contained"
          sx={{ bgcolor: "#F16101", ":hover": { bgcolor: "#F16101" } }}
          onClick={() =>
            navigate(
              `/dashboard/companyDashboard/customers/customerDetail/${id}`
            )
          }
        >
          { t('schedules.add_schedules') }
        </Button>
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", marginY: 5 }}
      >
        <Box>
          <Title>{ t('customers.customer_detail') }</Title>
          <Box sx={{ paddingLeft: 4 }}>
            <Typography
              sx={{
                fontSize: 19,
                fontWeight: "500",
                color: "#1976D2",
              }}
            >
               { t('general.name') }:{" "}
              <span style={{ fontSize: 18, fontWeight: "500", color: "black" }}>
                {custLoading ? "loading..." : customerData?.name}
              </span>
            </Typography>
            <Typography
              sx={{
                marginTop: 1,
                fontSize: 19,
                fontWeight: "500",
                color: "#1976D2",
              }}
            >
              { t('general.email') }:{" "}
              <span style={{ fontSize: 18, fontWeight: "500", color: "black" }}>
                {custLoading ? "loading..." : customerData?.email}
              </span>
            </Typography>
            <Typography
              sx={{
                marginTop: 1,
                fontSize: 19,
                fontWeight: "500",
                color: "#1976D2",
              }}
            >
              { t('general.address') } :{" "}
              <span style={{ fontSize: 18, fontWeight: "500", color: "black" }}>
                {custLoading ? "loading..." : customerData?.address}
              </span>
            </Typography>
            <Typography
              sx={{
                marginTop: 1,
                fontSize: 19,
                fontWeight: "500",
                color: "#1976D2",
              }}
            >
              { t('general.contact') }:{" "}
              <span style={{ fontSize: 18, fontWeight: "500", color: "black" }}>
                {custLoading ? "loading..." : customerData?.phone}
              </span>
            </Typography>
          </Box>
        </Box>
        <Box
          
        >
          <QRCodeBox qrCodeRef={qrCodeRef} checkInQRcodeData={checkInQRcodeData} onDataUrlChange={handleDataUrlChange}/>
          <DownloadMenu qrCodeRef={qrCodeRef} fileName={customerData?.name} dataUrl={dataUrl} />
        </Box>
      </Box>
      <Divider />
      <Divider />
      <Divider />
      <Box marginTop={2}>
        <Title>{ t('schedules.schedules') }</Title>
        {loading ? (
          <Typography
            sx={{
              textAlign: "center",
              fontSize: 24,
              fontWeight: "large",
              color: "#4793F7",
            }}
          >
            Loading...
          </Typography>
        ) : (
          // {/* Customers DataGrid */}
          <Box
            sx={{
              height: 500,
              width: "100%",
              "& .super-app-theme--header": {
                fontWeight: "700",
                fontSize: 21,
              },
              "& .super-app-theme--cell": {
                fontWeight: "500",
                fontSize: 18,
              },
            }}
          >
            <DataGrid
              loading={loading}
              columns={columns}
              rows={scheduleData}
              rowHeight={60}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 15]}
              getRowHeight={() => "auto"}
              localeText={{
                toolbarExport: "Export",
                toolbarExportCSV : t('data_grid.toolbar_export_csv'),
                toolbarExportPrint : t('data_grid.toolbar_export_print'),
                toolbarFilters : t('data_grid.toolbar_filters'),
                toolbarColumns : t('data_grid.toolbar_columns'),
                columnMenuSortAsc : t('data_grid.column_menu_sort_asc'),
                columnMenuSortDesc: t('data_grid.column_menu_sort_desc'),
                columnMenuHideColumn : t('data_grid.column_menu_hide_column'),
                columnMenuManageColumns : t('data_grid.column_menu_manage_columns'),
                rowsPerPageLabel : t('data_grid.rows_per_page_options')
              }}
              slotProps={{
                pagination: {
                  labelRowsPerPage: t('data_grid.rows_per_page_options'),
                  labelDisplayedRows : ({ from, to, count }) => {
                        const countText = count !== -1 ? count : `${t('general.more_than')} ${to}`;
                        return `${from}–${to} ${t('general.of')} ${countText}`;
                    } 
                }
              }}
          
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Schedule;
