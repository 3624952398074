import React, { useEffect, useRef } from "react";
import { Container, Typography, Box, Paper } from "@mui/material";
import ErrorImage from "../../../src/assets/emptyTable.webp";
import { useMutation } from "@apollo/client";
import { PAGE_NOT_FOUND } from "../../graphql/mutations";

const containerStyle = {
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const NotFound = () => {
  const [pageNotFound] = useMutation(PAGE_NOT_FOUND);
  const hasBeenCalledRef = useRef(false);

  useEffect(() => {
    if (!hasBeenCalledRef.current) {
        hasBeenCalledRef.current = true; // Set the ref to true immediately
        const fetchData = async () => {
          await pageNotFound({
            variables: {
                url: window.location.href,
            },
          });
        };
        fetchData();
    }
  }, [pageNotFound]);

  return (
    <Container maxWidth="xl" style={containerStyle}>
      <Box textAlign="center">
        <Paper elevation={3} style={{ padding: "16px" }}>
          <img src={ErrorImage} alt="Error" style={{ width: "100%" }} />
          <Typography variant="h4" component="h1">
            404 - Not Found
          </Typography>
          <Typography variant="body1">
            The page you are looking for does not exist.
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default NotFound;
