import { useQuery } from "@apollo/client";
import { Box, CardMedia, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { COMPANIES_DATA } from "../../graphql/queries";
import companyImage from "../images/company.png";
import Title from "../newLayout/Title";
import { useTranslation } from "react-i18next";

function AdminDashBoard() {
  const {t} = useTranslation('global');
  const [company, setCompany] = useState(0);
  const navigate = useNavigate();
  const { data: compData, loading } = useQuery(COMPANIES_DATA,{
    fetchPolicy:"no-cache"
  });
  useEffect(() => {
    try {
      setCompany(compData.companies.length);
    } catch (error) {
      console.log(error.message);
    }
  }, [compData]);
  return (
    <Box width="100%">
      <Box>
        <Title>{ t('admin') } { t('dashboard') }</Title>
      </Box>
      <Box
        width="100%"
        // border="1px solid red"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          marginTop: 3,
        }}
      >
        <Box
          sx={{
            width: "99%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "left",
            alignItems: "left",
            gap: 1,
            boxShadow: 2,
            // borderRadius: 3,
            paddingX: 3,
            paddingY: 1,
          }}
        >
          <Box>
            <CardMedia
              cursor="pionter"
              onClick={() => navigate("/dashboard/adminDashboard/companies")}
              component="img"
              alt="green iguana"
              sx={{ height: 100, width: 100, borderRadius: "50%" }}
              image={companyImage}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingX: 1,
            }}
          >
            <Typography
              sx={{
                marginTop: 1,
                fontSize: 22,
                fontWeight: 600,
                color: "green",
              }}
            >
              { t('general.company') }
            </Typography>
            <Typography
              sx={{
                fontSize: 26,
                fontWeight: 500,
                color: "blue",
              }}
            >
              {loading ? t('general.loading') : company}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AdminDashBoard;
