import { useMutation, useQuery } from "@apollo/client";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CUSTOMERS_EMPLOYEES,
  GET_DAYS,
  GET_SCHEDULES,
  GET_UPDATION_SCHEDULE,
} from "../../../graphql/queries";
import { AUTH_ID } from "../../Authentication/constant";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { UPDATE_SCHEDULE } from "../../../graphql/mutations";
import BasicSnackBar from "../BasicSnackBar";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomerDetail = () => {
  const navigate = useNavigate();
  const {t} = useTranslation('global');
  const { id: scheduleID } = useParams();
  // console.log("ID", scheduleID);
  const [checkDays, setCheckDays] = useState([]);
  const [selectDays, setSelectDays] = useState([]);
  const [employees, setEmployees] = useState([]);
  const userTemplate = {
    start_time: dayjs("h:mm A"),
    end_time: dayjs("h:mm A"),
    employee_id: [],
    days: [],
  };

  const [users, setUsers] = useState([userTemplate]);
  const [customer_id, setCustomer_id] = useState();
  const [empName, setEmpName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  // Query for get days...
  const { data: allDays } = useQuery(GET_DAYS);

  // Get Schedule Data for updation....
  const { data: scheduleUpdationData, loading: scheduleUpdationLoading } =
    useQuery(GET_UPDATION_SCHEDULE, {
      variables: { id: scheduleID },
    });
  // console.log("updSchData", scheduleUpdationData);
  // console.log("allDays", allDays);
  const getUpdateScheduleData = () => {
    try {
      const schDays = allDays.days;
      // console.log("allDays", schDays);
      setSelectDays(schDays);
      const schData = scheduleUpdationData?.schedule;
      setCustomer_id(schData.customer.id);
      setUsers([
        {
          start_time: schData.start_time,
          end_time: schData.end_time,
          employee_id: schData?.employees.map((employee) => employee.id),
          days: schData?.days.map((day) => day.id),
        },
      ]);
      // find pre selected days...
      // console.log("schData.days", schData.days);
      const preDays = schData.days.map((day) => {
        return {
          id: day.id,
          name: day.name,
        };
      });
      // console.log("predays", preDays);
      setCheckDays(preDays);
      // Find pre selected employees
      const preEmployees = schData.employees.map((employee) => {
        return {
          id: employee.id,
          name: employee.name,
        };
      });
      // console.log("preEmployees", preEmployees);
      setEmployees(preEmployees);
    } catch (error) {
      console.log("Update Schedule Error!", error.message);
    }
  };
  // console.log("user1", users);
  useEffect(() => {
    getUpdateScheduleData();
  }, [scheduleID, scheduleUpdationData, allDays]);

  const { data } = useQuery(CUSTOMERS_EMPLOYEES, {
    variables: { parent_id: AUTH_ID },
  });
  // console.log("employees1", employees);
  //-----------CreateSchedule-------------
  const [updateSchedule] = useMutation(UPDATE_SCHEDULE, {
    refetchQueries: [
      {
        query: GET_SCHEDULES,
        variables: { parent_id: AUTH_ID },
        awaitRefetchQueries: true,
      },
    ],
    onCompleted: ({ updateSchedule }) => {
      setOpenAlert(true);
      setTimeout(() => {
        navigate(`/dashboard/companyDashboard/customer-detail/${customer_id}`);
      }, 3000);
      setLoading(false);
    },
  });
  useEffect(() => {
    try {
      const employeesNames = data.employees.map((employee) => {
        return {
          id: employee.id,
          name: employee.name,
        };
      });
      // console.log("employeesNames", employeesNames);
      setEmpName(employeesNames);
    } catch (error) {
      console.log("employeesNames:", error.message);
    }
  }, [data]);

  const handleInputChange = (dateName, dateValue, index) => {
    if (dateName === "startTime") {
      const updateUsers = users.map((user, i) =>
        index === i
          ? Object.assign(user, {
              start_time: dateValue ? (dayjs(new Date(dateValue.toString())).format(
                "YYYY-MM-DD HH:mm:ss"
              ) ): "",
            })
          : user
      );
      // console.log("tttt:", updateUsers);
      setUsers(updateUsers);
    } else if (dateName === "endTime") {
      const updateUsers = users.map((user, i) =>
        index === i
          ? Object.assign(user, {
              end_time: dateValue ? (dayjs(new Date(dateValue.toString())).format(
                "YYYY-MM-DD HH:mm:ss"
              ) ) : "",
            })
          : user
      );
      // console.log("eeee:", updateUsers);
      setUsers(updateUsers);
    } else if (dateName === "employee_id") {
      const updateUsers = users.map((user, i) =>
        index === i
          ? Object.assign(user, {
              [dateValue.target.name]: dateValue.target.value,
            })
          : user
      );
      setUsers(updateUsers);
      const labelEmp = users?.map((user, i) => {
        if (index === i) {
          const preEmps = [...employees];
          const filterEmps = empName?.filter((emp) =>
            dateValue.target.value.includes(emp.id)
          );
          preEmps[index] = filterEmps;
          setEmployees(preEmps);
        }
      });
    } else {
      const updateUsers = users.map((user, i) =>
        index === i
          ? Object.assign(user, {
              [dateValue.target.name]: dateValue.target.value,
            })
          : user
      );
      setUsers(updateUsers);
      const labelDays = users?.map((user, i) => {
        if (index === i) {
          const preDays = [...checkDays];
          const filterDay = selectDays?.filter((day) =>
            dateValue.target.value.includes(day.id)
          );
          preDays[index] = filterDay;
          setCheckDays(preDays);
          return filterDay;
        } else {
          console.log("filter error in onchange method of days");
        }
      });
    }
  };

  //------------Submit Form Data Methodx
  // console.log("Users1", users);
  // console.log("selectallDays", selectDays);
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Users", users);
    if (customer_id && users) {
      setLoading(true);
      await updateSchedule({
        variables: {
          id: scheduleID,
          customer_id: customer_id,
          schedules: users,
        },
      });
    }
    // setOpenAlert(true);
    // setUsers([
    //   { start_time: dayjs(), end_time: dayjs(), employee_id: [], days: [] },
    // ]);
  };
  return (
    <React.Fragment>
      <Box>
        <ArrowBackIcon
          sx={{ color: "gray", marginBottom: "15px", fontSize: "28px" }}
          onClick={() =>
            navigate(
              `/dashboard/companyDashboard/customer-detail/${customer_id}`
            )
          }
        />
        {!scheduleUpdationLoading ? (
          <Box display={"flex"} flexDirection="column" justifyContent="center">
            <Typography variant="h6" gutterBottom>
              { t('schedules.update_schedule') }
            </Typography>
            <Box
              component={"form"}
              onSubmit={handleSubmit}
              sx={{ paddingX: 2, boxShadow: 2 }}
            >
              <Box
                sx={{
                  justifyContent: "space-between",
                  marginY: 3,
                }}
              >
                <Input type="hidden" name="customer_id" value={customer_id} />
              </Box>
              {users
                ? users.map((user, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "left",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              width: { xs: "100%" },
                              display: "flex",
                              alignItems: "flex-end",
                              marginBottom: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                width: { sm: 160, md: 140 },
                                display: { xs: "none", sm: "flex" },
                                fontSize: 20,
                                marginRight: 1,
                              }}
                            >
                             {t('days.days')}
                            </Typography>
                            <FormControl fullWidth>
                              <InputLabel id="demo-multiple-checkbox-label">
                              {t('days.days')}
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                label="Select Days"
                                required
                                name="days"
                                multiple
                                defaultValue="asasas"
                                value={user?.days}
                                onChange={(date) =>
                                  handleInputChange("days", date, index)
                                }
                                input={<OutlinedInput label="Select Days" />}
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected?.map((days, i) => {
                                      console.log("checkDays11111", checkDays);
                                      return (
                                        <Chip
                                          // key={checkDays[index][i].id}
                                          key={i}
                                          label={
                                            checkDays[i]?.name ||
                                            checkDays[index][i]?.name
                                          }
                                        />
                                      );
                                    })}
                                  </Box>
                                )}
                                MenuProps={MenuProps}
                              >
                                {selectDays.map((item) => {
                                  // console.log("dayId", item.id);
                                  // console.log("usersDays", user?.days);
                                  return (
                                    <MenuItem key={item.id} value={item.id}>
                                      <Checkbox
                                        checked={
                                          user?.days.indexOf(item.id) > -1
                                        }
                                      />
                                      <ListItemText primary={item.name} />
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Box>
                          <Box
                            width="100%"
                            sx={{
                              display: { xs: "block", md: "flex" },
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: 2,
                            }}
                          >
                            <Box
                              sx={{
                                width: { xs: "100%", md: "48%" },
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "flex-end",
                                gap: 0,
                              }}
                            >
                              <Typography
                                sx={{
                                  width: { sm: 160, md: 140 },
                                  fontSize: 20,
                                  marginRight: 1,
                                }}
                              >
                                {t('general.from')}:
                              </Typography>
                              <LocalizationProvider
                                fullWidth
                                dateAdapter={AdapterDayjs}
                              >
                                <TimePicker
                                  label= {t('general.from')}
                                  value={user?.start_time}
                                  name="startTime"
                                  onChange={(date) =>
                                    handleInputChange("startTime", date, index)
                                  }
                                  renderInput={(params) => (
                                    <TextField fullWidth {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </Box>
                            <Box
                              sx={{
                                width: { xs: "100%", md: "48%" },
                                marginTop: { xs: 2, md: 0 },
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "flex-end",
                                gap: 0,
                              }}
                            >
                              <Typography
                                sx={{
                                  width: { sm: 160, md: 58 },
                                  fontSize: 20,
                                  marginRight: 1,
                                  textAlign: { xs: "left", md: "right" },
                                }}
                              >
                                 {t('general.to')}:
                              </Typography>
                              <LocalizationProvider
                                fullWidth
                                dateAdapter={AdapterDayjs}
                              >
                                <TimePicker
                                  label={t('general.to')}
                                  value={user?.end_time}
                                  name="endTime"
                                  onChange={(date) =>
                                    handleInputChange("endTime", date, index)
                                  }
                                  renderInput={(params) => (
                                    <TextField fullWidth {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </Box>
                          </Box>
                          {/* employeeId */}
                          <Box
                            width="100%"
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "space-between",
                              gap: 2,
                              marginTop: 1,
                            }}
                          >
                            <Box
                              sx={{
                                width: { xs: "100%", md: "48%" },
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-end",
                              }}
                            >
                              <Typography
                                sx={{
                                  display: { xs: "none", sm: "flex" },
                                  fontSize: 20,
                                  marginRight: 1,
                                  width: { sm: 160, md: 140 },
                                }}
                              >
                                { t('employees.employees') }:
                              </Typography>
                              <FormControl fullWidth>
                                <InputLabel id="schedule-employee-name">
                                { t('employees.employees') }
                                </InputLabel>
                                <Select
                                  labelId="schedule-employee-name"
                                  id="schedule-employee"
                                  label="Employee Name"
                                  required
                                  name="employee_id"
                                  multiple
                                  value={user?.employee_id}
                                  onChange={(date) =>
                                    handleInputChange(
                                      "employee_id",
                                      date,
                                      index
                                    )
                                  }
                                  input={
                                    <OutlinedInput label="Select Employee ID" />
                                  }
                                  // renderValue={(selected) => selected.join(", ")}
                                  renderValue={(selected) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 0.5,
                                      }}
                                    >
                                      {selected?.map((emps, i) => {
                                        return (
                                          <Chip
                                            // key={employees[index][i].id}
                                            key={i}
                                            label={
                                              employees[i]?.name ||
                                              employees[index][i]?.name
                                            }
                                          />
                                        );
                                      })}
                                    </Box>
                                  )}
                                  MenuProps={MenuProps}
                                >
                                  {empName?.map((item) => {
                                    return (
                                      <MenuItem key={item.id} value={item.id}>
                                        <Checkbox
                                          checked={
                                            user?.employee_id?.indexOf(
                                              item?.id
                                            ) > -1
                                          }
                                        />
                                        <ListItemText primary={item.name} />
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>
                          </Box>
                        </Box>
                      </React.Fragment>
                    );
                  })
                : "Loading..."}
              <Box
                sx={{
                  display: { xs: "block", sm: "flex" },
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  marginTop: 3,
                  marginBottom: 2,
                }}
              >
                <Button
                  onClick={() =>
                    navigate(
                      `/dashboard/companyDashboard/customer-detail/${customer_id}`
                    )
                  }
                  variant="contained"
                  sx={{
                    width: { xs: 200 },
                    fontSize: { xs: 16 },
                    bgcolor: "#ff9800",
                    ":hover": { bgcolor: "#ff9800" },
                  }}
                >
                   { t('general.cancel') }
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    width: { xs: 200, sm: 230 },
                    fontSize: { xs: 16 },
                    marginTop: { xs: 2, sm: 0 },
                    bgcolor: "#F16101",
                    ":hover": { bgcolor: "#F16101" },
                  }}
                  disabled={loading}
                >
                  {loading ? "updating..." :  t('schedules.update_schedule') }
                </Button>
              </Box>
            </Box>
            {/* form Box end */}
          </Box>
        ) : (
          <h3 style={{ textAlign: "center", color: "#1976D2" }}>Loading...</h3>
        )}
      </Box>
      <BasicSnackBar
        open={openAlert}
        onClose={handleAlertClose}
        severity="success"
        message="schedule updated successfully!"
      />
    </React.Fragment>
  );
};

export default CustomerDetail;
