import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Box, TextField } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const ReusableDatePicker = ({ label, initialValue, onDateChange }) => {
  const [value, setValue] = useState(initialValue);

  const handleDateChange = (newValue) => {
    const formattedValue = newValue ? dayjs(new Date(newValue.toString())).format("MM-DD-YYYY") : null;
    setValue(formattedValue);
    onDateChange(formattedValue);
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={label}
          value={value}
          onChange={handleDateChange}
          renderInput={(params) => (
            <TextField fullWidth {...params} sx={{ paddingRight: "10px" }}/>
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

export default ReusableDatePicker;
