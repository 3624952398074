import React, { useEffect, useRef } from "react";
import {
  Alert,
  Box,
  Button,
  Divider,
  Input,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Popover,
  styled,
  TableCell,
  tableCellClasses,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { Link } from "react-router-dom";
import { useState } from "react";
import Title from "../../newLayout/Title";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import {
  COSTUMER_DATA,
  GET_CHECK_OUT_QRCODE_DATA,
} from "../../../graphql/queries";
import {
  DELETE_CUSTOMER,
  UPDATE_OR_CREATE_CUSTOMER,
} from "../../../graphql/mutations";
import PopupState, {
  bindPopover,
  bindTrigger,
  bindToggle,
} from "material-ui-popup-state";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import swal from "sweetalert";
import { AUTH_ID } from "../../Authentication/constant";
import CircularProgress from "@mui/material/CircularProgress";
import BasicSnackBar from "../BasicSnackBar";
import DetailsIcon from "@mui/icons-material/Details";
import { DataGrid } from "@mui/x-data-grid";
import QRCodeBox from "../../QRCodeBox";
import DownloadMenu from "../../DownloadMenu";
import { useTranslation } from "react-i18next";


const StyledTableCell = styled(TableCell)(() => ({
  textAlign: "center",
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 700,
  },
}));

const boxStyled = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

function Customers() {
  const qrCodeRef = useRef();
  const {t} = useTranslation("global");
  const initialValues = {
    name: "",
    email: "",
    phone: "",
    address: "",
    paid_per_hr: ""
  };
  const [customerData, setCustomerData] = useState([]);
  const [newCustomer, setNewCustomer] = useState(initialValues);
  const [checkOutQRcodeData, setCheckOutQRCodeData] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setmodalOpen] = useState(false);
  const [modalOpenQRCode, setmodalOpenQRCode] = useState(false);
  const [errors, setErrors] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [fileName, setFileName] = useState("QRCode");
  const [dataUrl, setDataUrl] = useState('');

  const handleDataUrlChange = (url) => {
    setDataUrl(url); // Receive dataUrl from the QRCodeBox component
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  
  const handleOpen = () => setmodalOpen(true);
  const handleClose = () => setmodalOpen(false);
  
  //........Costumer Query..................
  const { data, loading } = useQuery(COSTUMER_DATA, {
    variables: { parent_id: AUTH_ID },
    fetchPolicy: 'no-cache'
  });
  const handleCloseQRCode = () => setmodalOpenQRCode(false);

  //....... Fetch checkout data ......//
  const [getCheckOutData, { data: checkOutData }] = useLazyQuery(
    GET_CHECK_OUT_QRCODE_DATA, 
    { 
      fetchPolicy: 'no-cache'
    }
  );
  
  //........Costumer Mutation...............
  const [CreateCostumer, { data: cusData, loading: cusLoading }] = useMutation(UPDATE_OR_CREATE_CUSTOMER,{
    onError:(()=>{
      console.log("Create mutation error.");
    })
  });

  useEffect(() => {
    try {
      setCustomerData(data.customers);
      if(checkOutData){
        setCheckOutQRCodeData(
          "wcId=" +
            checkOutData.me.id +
            "&wcName=" +
            checkOutData.me.name +
            "&ccId=" +
            checkOutData.customer.id +
            "&ccName=" +
            checkOutData.customer.name
        );
        setFileName(checkOutData.customer.name);
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [data, checkOutData]);

  useEffect(() => {
    if (cusData) {
      setCustomerData(prevCustomerData => [
        cusData.updateOrCreateCustomer,
        ...prevCustomerData,
      ]);
    }
  }, [cusData]);
  
  const onValueChange = (e) => {
    let data = { ...newCustomer };
    let name = e.target.name;
    let val = e.target.value;
    if (
      name === "name" ||
      name === "email" ||
      name === "paid_per_hr" ||
      name === "phone" ||
      name === "address" 
    ) {
      data = { ...data, [name]: val };
    }
    setNewCustomer(data);
  };

  const handleClick = async () => {
    if (
      newCustomer.name &&
      newCustomer.address 
    ) {
      const response = await CreateCostumer({
        variables: {
          name: newCustomer.name,
          email: newCustomer.email,
          paid_per_hr: (newCustomer.paid_per_hr ? parseFloat(newCustomer.paid_per_hr) : 0),
          phone: newCustomer.phone,
          address: newCustomer.address,
          parent_id: AUTH_ID,
        },
      });

      if (response && response.data) {
       
        setmodalOpen(false);
        setNewCustomer("");
        setOpenAlert(true);
        setErrors(false);

      } else if (response && response.errors) {
        const graphQLErrors = response.errors.graphQLErrors;
        if (graphQLErrors) {
          const errorMessages = [];
          graphQLErrors.forEach((error) => {
            const validationErrors = error.extensions.validation;
            if (validationErrors) {
              Object.keys(validationErrors).forEach((fieldName) => {
                const errorMessage = validationErrors[fieldName][0];
                errorMessages.push(errorMessage);
              });
            }
          });
          setErrors(errorMessages);
        }
      }

    } else {
      setErrors(["Name and address are required"]);
      setmodalOpen(true);
    }
  };

  // delete Customer Mutation.
  const [deleteCustomer] = useMutation(DELETE_CUSTOMER);

  const handleDeleteClick = (id) => {
    swal({
      title: t('pop_ups.are_you_sure'),
      text: t('pop_ups.customer_delete_confirm'),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteCustomer({
          variables: {
            id: id,
          }
        })
          .then((response) => {
            console.log(response);
            if(response.data.deleteCustomer.success){

              const indexToDelete = customerData.findIndex((customer) => customer.id === id);
              if (indexToDelete !== -1) {
                // Create a new array without the deleted row
                const updatedCustomerData = [...customerData];
                updatedCustomerData.splice(indexToDelete, 1);
                setCustomerData(updatedCustomerData);
              }

              swal(t('pop_ups.customer_deleted'), {
                icon: "success",
              });
            }else{
              swal(response.data.deleteCustomer.message, {
                icon: "error",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            swal(t('pop_ups.error_occurred'), {
              icon: "error",
            });
          });
      } else {
        swal(t('pop_ups.customer_is_safe'));
      }
    });
  };

  //........... run the query getCheckOutData  .............//
  const getCheckOutQCodeData = (params) => { 
    getCheckOutData({
      variables: {
        id: params.id,
      },
    });
  };

  // Column Def of Customer DataGrid.
  const columns = [
    {
      field: "name",
      headerName: t('general.name'),
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => (
        <Link
          style={{ color: "#1976D2", textDecoration: "none" }}
          to={`/dashboard/companyDashboard/customer-detail/${params.id}`}
        >
          {params.row.name}
        </Link>
      ),
    },
    {
      field: "email",
      headerName: t('general.email'),
      minWidth: 230,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "phone",
      headerName: t('general.contact'),
      minWidth: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      valueGetter: (params) => `${(params.row.phone?params.row.phone:"")}`,
    },
    {
      field: "address",
      headerName: t('general.address'),
      minWidth: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "paid",
      headerName: t('customers.paid_hr'),
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      valueGetter: (params) => {
        if (params.row.paid_per_hr === 0) {
          return '';
        } else {
          if(params.row.user.currency){
            return `${params.row.user.currency.symbol}${params.row.paid_per_hr.toFixed(2)}`
          }else{
            return `$${params.row.paid_per_hr.toFixed(2)}`;
          }
        }
      },
    },
    {
      field: "actions",
      headerName: t('general.actions'),
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => (
        <StyledTableCell>
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <div>
                {/* Action in Table Cell */}
                <MoreVertIcon
                  sx={{ color: "gray", fontSize: "28px" }}
                  {...bindTrigger(popupState)}
                />
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Box sx={{ display: "block" }}>
                    <Link
                      to={`/dashboard/companyDashboard/customers/updateCustomer/${params.id}`}
                      style={{
                        textDecoration: "none",
                        color: "gray",
                        hover: {
                          bgcolor: "gray",
                        },
                      }}
                    >
                      <ListItemButton {...bindToggle(popupState)}>
                        <ListItemIcon>
                          <EditIcon />
                        </ListItemIcon>
                        <ListItemText primary={ t('customers.update_customer') } />
                      </ListItemButton>
                    </Link>
                    <Divider width="100%" flexItem />
                    <Link
                      onClick={ () => handleDeleteClick(params.id) }
                      style={{
                        textDecoration: "none",
                        color: "gray",
                      }}
                    >
                      <ListItemButton {...bindToggle(popupState)}>
                        <ListItemIcon>
                          <DeleteIcon color="error" />
                        </ListItemIcon>
                        <ListItemText primary={ t('customers.delete_customer') } />
                      </ListItemButton>
                    </Link>
                    <Divider width="100%" flexItem />
                    <Link
                      to={`/dashboard/companyDashboard/customer-detail/${params.id}`}
                      style={{
                        textDecoration: "none",
                        color: "gray",
                      }}
                    >
                      <ListItemButton {...bindToggle(popupState)}>
                        <ListItemIcon>
                          <DetailsIcon color="primary" />
                        </ListItemIcon>
                        {/* Also show schedules of customer */}
                        <ListItemText primary={ t('customers.customer_detail') } />
                      </ListItemButton>
                    </Link>
                    <Divider width="100%" flexItem />
                    {/* generate QRCode */}
                    <Link
                      onClick={() => {
                        setmodalOpenQRCode(true);
                        setCheckOutQRCodeData("");
                        getCheckOutQCodeData(params);
                      }}
                      style={{
                        textDecoration: "none",
                        color: "gray",
                      }}
                    >
                      <ListItemButton {...bindToggle(popupState)}>
                        <ListItemIcon>
                          <QrCodeIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={ t('general.generate_qrcode') } />
                      </ListItemButton>
                    </Link>
                  </Box>
                </Popover>
              </div>
            )}
          </PopupState>
        </StyledTableCell>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Title>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            marginBottom: "20px",
          }}
        >
          <Button
            onClick={handleOpen}
            variant="contained"
            sx={{ bgcolor: "#F16101", ":hover": { bgcolor: "#F16101" } }}
          >
            { t('customers.add_customer') }
          </Button>
        </Box>
      </Title>
      {/* Customers DataGrid */}
      <Box
        sx={{
          height: "80vh",
          width: "100%",
          "& .super-app-theme--header": {
            fontWeight: "700",
            fontSize: 18,
          },
          "& .super-app-theme--cell": {
            fontSize: 18,
          },
        }}
      >
        <DataGrid
          loading={loading}
          columns={columns}
          rows={customerData}
          getRowId={(row) => row.id}
          rowHeight={60}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 30]}
          localeText={{
            toolbarExport: t('data_grid.export'),
            toolbarExportCSV : t('data_grid.toolbar_export_csv'),
            toolbarExportPrint : t('data_grid.toolbar_export_print'),
            toolbarFilters : t('data_grid.toolbar_filters'),
            toolbarColumns : t('data_grid.toolbar_columns'),
            columnMenuSortAsc : t('data_grid.column_menu_sort_asc'),
            columnMenuSortDesc: t('data_grid.column_menu_sort_desc'),
            columnMenuHideColumn : t('data_grid.column_menu_hide_column'),
            columnMenuManageColumns : t('data_grid.column_menu_manage_columns'),
            toolbarDensity: t('data_grid.toolbar_density_label'),
            toolbarDensityLabel: t('data_grid.toolbar_density_label'),
            toolbarDensityCompact: t('data_grid.toolbar_density_compact'),
            toolbarDensityStandard: t('data_grid.toolbar_density_standard'),
            toolbarDensityComfortable: t('data_grid.toolbar_density_comfortable'),
          }}
          slotProps={{
            pagination: {
              labelRowsPerPage: t('data_grid.rows_per_page_options'),
              labelDisplayedRows : ({ from, to, count }) => {
                    const countText = count !== -1 ? count : `${t('general.more_than')} ${to}`;
                    return `${from}–${to} ${t('general.of')} ${countText}`;
                } 
            }
          }}
        />
      </Box>

      {/* Add Customer Modal */}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyled}>
          <Box>
            {/* component="form" onSubmit={handleSubmit} */}
            <Typography variant="h6" textAlign="center">
             { t('customers.add_customer_details') }
            </Typography>
            <TextField
              name="name"
              fullWidth
              value={newCustomer.name ? newCustomer.name : ""}
              onChange={(e) => onValueChange(e)}
              sx={{ m: 1 }}
              label={ t('general.name') }
            />
            <TextField
              type="email"
              name="email"
              fullWidth
              value={newCustomer.email ? newCustomer.email : ""}
              onChange={(e) => onValueChange(e)}
              sx={{ m: 1 }}
              label={ t('general.email') }
            />
            <TextField
              name="paid_per_hr"
              fullWidth
              value={newCustomer.paid_per_hr ? newCustomer.paid_per_hr : ""}
              onChange={(e) => onValueChange(e)}
              sx={{ m: 1 }}
              label={ t('customers.paid_hr') }
            />
            <TextField
              name="phone"
              fullWidth
              value={newCustomer.phone ? newCustomer.phone : ""}
              onChange={(e) => onValueChange(e)}
              sx={{ m: 1 }}
              label={ t('general.contact') }
            />
            <TextField
              name="address"
              fullWidth
              value={newCustomer.address ? newCustomer.address : ""}
              onChange={(e) => onValueChange(e)}
              sx={{ m: 1 }}
              label={ t('general.address') }
            />
     
            {errors?.length > 0 && (
              <div>
                {errors.map((errorMessage, index) => (
                  <Alert key={index} severity="error">
                    {errorMessage}
                  </Alert>
                ))}
              </div>
            )}

            <Box sx={{ mt: 3, mb: 2, textAlign: "center" }}>
              <Button
                onClick={() => {
                  handleClose();
                  setNewCustomer("");
                  setErrors(false);
                }}
                variant="contained"
                sx={{
                  minWidth: "150px",
                  p: 1,
                  m: 1,
                  bgcolor: "#ff9800",
                  ":hover": { bgcolor: "#ff9800" },
                }}
              >
                 { t('general.cancel') }
              </Button>
              <Button
                sx={{
                  minWidth: "150px",
                  p: 1,
                  m: 1,
                  bgcolor: "#F16101",
                  ":hover": { bgcolor: "#F16101" },
                }}
                variant="contained"
                onClick={() => handleClick()}
                disabled={cusLoading}
              >
                {cusLoading ? (
                  <CircularProgress size={24} color="primary" />
                ) : (
                  t('general.add') 
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* Success Alert Message */}
      <BasicSnackBar
        open={openAlert}
        onClose={handleAlertClose}
        severity="success"
        message={ t('pop_ups.customer_added') }
      />

      {/* Generate QRCode Modal. */}
      <Modal
        open={modalOpenQRCode}
        onClose={handleCloseQRCode}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyled}>
          <QRCodeBox qrCodeRef={qrCodeRef} checkInQRcodeData={checkOutQRcodeData} onDataUrlChange={handleDataUrlChange} />
          <DownloadMenu qrCodeRef={qrCodeRef} fileName={fileName} dataUrl={dataUrl}/>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
export default Customers;
