import React, { useEffect, useState, useRef } from "react";
import { Box, CardMedia, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import {
  COSTUMER_DATA,
  EMPLOYEE_DATA,
  PENDING_CHECKOUTS,
} from "../../graphql/queries";
import customerImage from "../images/customer.png";
import employeeImage from "../images/employee.png";
import scheduleImage from "../images/schedule.webp";
import { useNavigate } from "react-router-dom";
import { AUTH_ID } from "../Authentication/constant";
import Title from "../newLayout/Title";
import DownloadMenu from "../DownloadMenu";
import QRCodeBox from "../QRCodeBox";
import { useTranslation } from "react-i18next";

const CompanyDashBoard = ({ loggedInUser }) => {
  const qrCodeRef = useRef();
  const [dataUrl, setDataUrl] = useState('');
  const [checkInQRcodeData, setCheckInQRCodeData] = useState("");
  const [customers, setCustomers] = useState([]);
  const [employee, setEmployees] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const navigate = useNavigate();
  const {t} = useTranslation('global');

  const handleDataUrlChange = (url) => {
    setDataUrl(url); // Receive dataUrl from the QRCodeBox component
  };

  const { data: cusData, loading } = useQuery(COSTUMER_DATA, {
    variables: { parent_id: AUTH_ID },
    fetchPolicy: "no-cache",
  });
  const { data: empData } = useQuery(EMPLOYEE_DATA, {
    variables: { parent_id: AUTH_ID },
    fetchPolicy: "no-cache",
  });
  const { data: schData } = useQuery(PENDING_CHECKOUTS,{
    fetchPolicy: "no-cache",
  });

  const navigateToEmployees = () => {
    navigate("/dashboard/companyDashboard/employees");
  };
  const navigateToPendingCheckouts = () => {
    navigate("/dashboard/companyDashboard/pending-checkouts");
  };
  const navigateToCustomers = () => {
    navigate("/dashboard/companyDashboard/customers");
  };
  useEffect(() => {
    setCheckInQRCodeData("cn=" + loggedInUser?.name + "&ci="); // Access the name property of loggedInUser
    try {
      setCustomers(cusData?.customers);
      setEmployees(empData?.employees);
      setSchedules(schData?.pendingCheckOuts);
    } catch (error) {
      console.log(error.message);
    }
  }, [cusData, empData, schData, loggedInUser]);

  return (
    <Box width="100%">
      <Box>
        <Title>{ t('general.company') }  { t('dashboard') }</Title>
      </Box>
      <Box
        width="100%"
        // border="1px solid red"
        sx={{
          display: { xs: "none", sm: "block", md: "flex" },
          flexWrap: "wrap",
          justifyContent: "space-between",
          marginTop: 3,
        }}
      >
        {/* Customers */}
       <Box
          onClick={navigateToCustomers}
          sx={{
            width: { xs: "99%", lg: "33%" },
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            gap: 1,
            boxShadow: 2,
            paddingX: 3,
            paddingY: 1,
            cursor: "pointer", // Set the cursor to indicate the Box is clickable
          }}
        >
          <Box>
            <CardMedia
              onClick={navigateToCustomers}
              component="img"
              alt="green iguana"
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                height: 100,
                width: 100,
                borderRadius: "50%",
              }}
              image={customerImage}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingX: 1,
            }}
          >
            <Typography
              onClick={navigateToCustomers}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                marginTop: 1,
                fontSize: 22,
                fontWeight: 600,
                color: "green",
              }}
            >
              { t('customers.customers') }
            </Typography>
            <Typography
              sx={{
                fontSize: 26,
                fontWeight: 500,
                color: "blue",
              }}
            >
              {loading ? t('general.loading') : customers ? customers.length : 0}
            </Typography>
          </Box>
       </Box>
        {/* employees */}
        <Box
          onClick={navigateToEmployees}
          sx={{
            width: { xs: "99%", lg: "33%" },
            display: "flex",
            // flexWrap: "wrap",
            justifyContent: "left",
            alignItems: "center",
            gap: 1,
            boxShadow: 2,
            paddingX: 3,
            paddingY: 1,
            cursor: "pointer", // Set the cursor to indicate the Box is clickable
          }}
        >
          <Box>
            <CardMedia
              cursor="pointer"
              component="img"
              alt="green iguana"
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                height: 100,
                width: 100,
                borderRadius: "50%",
              }}
              image={employeeImage}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingX: 1,
            }}
          >
            <Typography
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                marginTop: 1,
                fontSize: 22,
                fontWeight: 600,
                color: "green",
              }}
            >
              { t('employees.employees') }
            </Typography>
            <Typography
              sx={{
                fontSize: 26,
                fontWeight: 500,
                color: "blue",
              }}
            >
              {loading ? t('general.loading') : employee ? employee?.length : 0}
            </Typography>
          </Box>
        </Box>
        {/* Pending checkouts */}
        <Box
          onClick={navigateToPendingCheckouts}
          sx={{
            width: { xs: "99%", lg: "33%" },
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            gap: 1,
            boxShadow: 2,
            // borderRadius: 3,
            paddingX: 3,
            paddingY: 1,
            cursor: "pointer", // Set the cursor to indicate the Box is clickable
          }}
        >
          <Box>
            <CardMedia
              cursor="pointer"
              component="img"
              alt="green iguana"
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                height: 100,
                width: 100,
                borderRadius: "50%",
              }}
              image={scheduleImage}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingX: 1,
            }}
          >
            <Typography
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                marginTop: 1,
                fontSize: 22,
                fontWeight: 600,
                color: "green",
              }}
            >
              { t('pending_checkouts.pending_checkouts') }
            </Typography>
            <Typography
              sx={{
                fontSize: 26,
                fontWeight: 500,
                color: "blue",
              }}
            >
              {loading ? t('general.loading') : schedules ? schedules.length : 0}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* QR code main Box */}
      <Box marginTop={3} boxShadow={2} padding={2}>
        <Typography
          variant="h6"
          align="center"
          sx={{
            marginBottom: 4,
            fontSize: 24,
            fontWeight: "bold",
          }}
        >
          { t('pending_to_approve.company_qrcode') }
        </Typography>

        <QRCodeBox qrCodeRef={qrCodeRef} checkInQRcodeData={checkInQRcodeData} onDataUrlChange={handleDataUrlChange}/>

        {/* download images menu */}
        <DownloadMenu qrCodeRef={qrCodeRef} fileName={loggedInUser?.name} dataUrl={dataUrl}/>
      </Box>
    </Box>
  );
};

export default CompanyDashBoard;
