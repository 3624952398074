import React, { useState, useLayoutEffect, useEffect } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import LayersIcon from "@mui/icons-material/Layers";
import DescriptionIcon from "@mui/icons-material/Description";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { Collapse, List, Typography } from "@mui/material";

export const MainListItems = ({ roleId, t, i18n }) => {
  const [dashboardList, setDashboardList] = useState();
  const [open, setOpen] = useState(false);

  const handleOpenClick = () => {
    setOpen(!open);
  };

  const handleUsers = () => {
    if (localStorage.getItem("role_id") === "1") {
      const layoutItems = [
        {
          path: "/dashboard/adminDashboard",
          name: t('dashboard'),
          icon: <DashboardIcon />,
        },
        {
          path: "/dashboard/adminDashboard/companies",
          name: t('general.company'),
          icon: <ShoppingCartIcon />,
        },
      ];
      setDashboardList(layoutItems);
    } else {
     
      const layoutItems = [
        {
          path: "/dashboard/companyDashboard",
          name:  t('dashboard'),
          icon: <DashboardIcon />,
        },
        {
          path: "/dashboard/companyDashboard/customers",
          name: t('customers.customers'),
          icon: <LayersIcon />,
        },
        {
          path: "/dashboard/companyDashboard/employees",
          name: t('employees.employees'),
          icon: <PeopleIcon />,
        },
        {
          path: "/dashboard/companyDashboard/pending-checkouts",
          name: t('pending_checkouts.pending_checkouts'),
          icon: <PendingActionsIcon />,
        },
        // {
        //   path: "/dashboard/companyDashboard/summary",
        //   name: "Summary",
        //   icon: <DescriptionIcon />,
        // },
      ];
      setDashboardList(layoutItems);
    }
  };
 
  useLayoutEffect(() => {
    handleUsers();
  }, []);

  useEffect(() => {
    handleUsers();
  }, [roleId, i18n, t]);

  return (
    <React.Fragment>
      {dashboardList
        ? dashboardList.map((value, index) => {
            // console.log("val", value.name,"index", index,);
            return (
              <Link
                key={index}
                to={value.path}
                style={{ textDecoration: "none", color: "gray" }}
              >
                <ListItemButton>
                  <ListItemIcon>{value.icon}</ListItemIcon>
                  <ListItemText primary={value.name} />
                </ListItemButton>
              </Link>
            );
          })
        : "null listItem.js"}
      {/* Reports Menu */}

      {roleId !== "1" && (
        <ListItemButton onClick={handleOpenClick}>
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText
            // sx={{ maxWidth: 100 }}
            primary={
              <Typography sx={{ fontSize: 16, color: "gray", marginLeft: 0 }}>
                { t('reports.reports') } 
              </Typography>
            }
          />
          {open ? (
            <ExpandLess style={{ width: 23, height: 23 }} />
          ) : (
            <ExpandMore style={{ width: 23, height: 23 }} />
          )}
        </ListItemButton>
      )}

      {/* Report dropdown menu */}

      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        // sx={{ maxHeight: "50vh", overflowY: "scroll", overflowX: "hidden" }}
      >
        <List component="div" disablePadding>
          <Link
            style={{ textDecoration: "none", color: "gray" }}
            to="/dashboard/companyDashboard/checkincheckout"
          >
            <ListItemButton
              sx={{
                paddingY: 0,
              }}
            >
              <ListItemText
                primary={
                  <Typography sx={{ fontSize: 16, marginLeft: 8 }}>
                    { t('reports.cico_report') } 
                  </Typography>
                }
              />
            </ListItemButton>
          </Link>
          <Link
            style={{ textDecoration: "none", color: "gray" }}
            to="/dashboard/companyDashboard/summary"
          >
            <ListItemButton
              sx={{
                paddingY: 0,
              }}
            >
              <ListItemText
                primary={
                  <Typography sx={{ fontSize: 16, marginLeft: 8 }}>
                    { t('reports.summary') }
                  </Typography>
                }
              />
            </ListItemButton>
          </Link>
        </List>
      </Collapse>
    </React.Fragment>
  );
};
