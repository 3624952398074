import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { AUTH_TOKEN, ROLE_ID, USER_ID } from "./components/Authentication/constant";
import { AuthProvider } from "./context/authContext";
import { createUploadLink } from "apollo-upload-client";
import global_en from "../src/translations/en/global.json";
import global_de from "../src/translations/de/global.json";
import global_es from "../src/translations/es/global.json";
import global_th from "../src/translations/th/global.json";
import global_ar from "../src/translations/ar/global.json";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { GRAPHQL_URL } from "../src/components/Authentication/config";
import {onError} from "@apollo/client/link/error";

const root = ReactDOM.createRoot(document.getElementById("root"));

const httpLink = createUploadLink({
  uri: GRAPHQL_URL
});

// Create an error link to handle errors globally
const errorLink = onError(({ graphQLErrors }) => {
  graphQLErrors.forEach(({ message, extensions }) => {
    const regex = /unauthenticated/i;
    if(regex.test(message)){
      handleUnauthenticatedError();
    }
  });
});

const handleUnauthenticatedError = () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(ROLE_ID);
  localStorage.removeItem(USER_ID);
  localStorage.removeItem('lang');
  window.location.href = "/";
};

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: "Bearer "+localStorage.getItem(AUTH_TOKEN) || "",
    },
  };
});
const client = new ApolloClient({
  link: errorLink.concat(authLink).concat(httpLink), // Concatenate the errorLink, authLink, and httpLink
  // link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

i18next.init({

    interpolation : { escapeValue : true },
    lng : localStorage.getItem('lang') || 'en',
    resources : {
      en : {
        global : global_en,
      },
      de : {
        global : global_de,
      },
      es : {
        global : global_es,
      },
      th : {
        global : global_th,
      },
      ar : {
        global : global_ar,
      },
    },

});

root.render(
  <React.StrictMode>
    <AuthProvider>
      <ApolloProvider client={client}>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </ApolloProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
