import { useNavigate } from "react-router-dom";

function AdminProtected({ roleId, children }) {
  const navigate = useNavigate();
  if (roleId === '1') {
    return children;
  }
  return navigate("/");
}

export default AdminProtected;
