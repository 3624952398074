import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  IconButton, // Import IconButton from MUI
  InputAdornment, // Import InputAdornment from MUI
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material"; // Import icons for visibility toggle
import Footer from "../border/Footer";
import Header from "../border/Header";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "../../graphql/mutations";
import { FRONT_END_URL } from "./config";
import { AUTH_TOKEN } from "./constant";
import { useParams, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  
    const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get("email");
    const tokenParam = urlParams.get("token");

    if (emailParam && tokenParam) {
      setEmail(emailParam);
      setToken(tokenParam);
    }
  }, []);

  const [resetPassword] = useMutation(
    RESET_PASSWORD,
    {
        onCompleted: () => {         
            setTimeout(() => {
              navigate(`/`);
            }, 2000);
        }
    }
  );

  const passwordPattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,20}$/;

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match. Please check again.");
      return;
    }

    if (!passwordPattern.test(password)) {
      setErrorMessage(
        "Password must be 8-20 characters long, contain at least one uppercase letter, one number, and one special character."
      );
      return;
    }

    setLoading(true);

    try {
      await resetPassword({
        variables: {
          email: email,
          token: token,
          password: password,
          password_confirmation: confirmPassword,
        },
      });

      setSuccessMessage("Password reset successfully.");
      setErrorMessage("");
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage("Failed to reset password. Please try again.");
    }

    setLoading(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Box
      height="100vh"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Header />
      </Box>

      <Box
        width="100%"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Box
          component="form"
          onSubmit={handleResetPassword}
          sx={{
            width: { xs: "95%", sm: 450 },
            boxShadow: 2,
            borderRadius: 3,
            px: 3,
            py: 4,
          }}
        >
          <Typography
            sx={{
              flexGrow: 1,
              color: "#FF6D00",
              textAlign: "center",
              fontSize: 20,
              fontWeight: "600",
            }}
          >
            RESET PASSWORD
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", marginTop: 3 }}>
            <TextField
              sx={{ bgcolor: "#E8F0FE", marginBottom:5 }}
              required
              fullWidth
              type={showPassword ? "text" : "password"} // Toggle password visibility
              id="password"
              label="New Password"
              name="password"
              autoComplete="new-password"
              value={password}
              onChange={handlePasswordChange}
              inputProps={{ style: { fontSize: 17 } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              sx={{ bgcolor: "#E8F0FE" }}
              required
              fullWidth
              type={showConfirmPassword ? "text" : "password"}
              id="confirmPassword"
              label="Confirm Password"
              name="confirmPassword"
              autoComplete="confirm-password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              inputProps={{ style: { fontSize: 17 } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleConfirmPasswordVisibility}
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {successMessage && (
              <Alert severity="success" style={{ marginTop: 10 }}>
                {successMessage}
              </Alert>
            )}
            {errorMessage && (
              <Alert severity="error" style={{ marginTop: 10 }}>
                {errorMessage}
              </Alert>
            )}
          </Box>
          <Box marginTop={5} display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "#ff6d00",
                width: 160,
                marginBottom: "5px",
                fontSize: 17,
                fontWeight: "600",
              }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress color="inherit" size={32} />
              ) : (
                "Reset Password"
              )}
            </Button>
          </Box>
        </Box>
      </Box>

      <Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default ResetPassword;
