import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { AUTH_TOKEN, ROLE_ID, USER_ID } from "./constant";
// import GoogleIcon from "@mui/icons-material/Google";
import { Alert, TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import EmailIcon from "@mui/icons-material/Email";
import { LOGIN_MUTATION } from "../../graphql/mutations";
import Footer from "../border/Footer";
import Header from "../border/Header";

const theme = createTheme();

const SignIn = () => {
  const initialValues = {
    email: "",
    password: "",
  };
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  //UseMutaion for Login Page.

  const [login, { loading }] = useMutation(LOGIN_MUTATION, {
    onError({ graphQLErrors }) {
      // console.log("loginError");
      setErrors(graphQLErrors);
    },
    onCompleted: ({ login }) => {
      // console.log("login", login);
      localStorage.setItem(AUTH_TOKEN, login.token);
      localStorage.setItem(ROLE_ID, login.user.role.id);
      localStorage.setItem(USER_ID, login.user.id);
      localStorage.setItem('lang', login.user.language);
      localStorage.getItem(ROLE_ID) === "1"
        ? (window.location.href = "/dashboard/adminDashboard")
        : (window.location.href = "/dashboard/companyDashboard");
    },
  });
  //Handle Change.
  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  // login button function.
  const handleLoginClick = async (e) => {
    e.preventDefault();
    await login({
      variables: {
        email: values.email,
        password: values.password,
      },
    });
  };
  return (
    <Box
      height="100vh"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Header />
      </Box>
      {/* Sign In Form */}
      <Box width="100%" sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          component="form"
          onSubmit={handleLoginClick}
          sx={{
            width: { xs: "95%", sm: 450 },
            boxShadow: 2,
            borderRadius: 3,
            px: 3,
            py: 4,
          }}
        >
          <Typography
            sx={{
              flexGrow: 1,
              color: "#FF6D00",
              textAlign: "center",
              fontSize: 20,
              fontWeight: "600",
            }}
          >
            SIGN IN
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", marginTop: 3 }}>
            <TextField
              sx={{ bgcolor: "#E8F0FE" }}
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={values.email}
              onChange={handleChange}
              inputProps={{ style: { fontSize: 17 } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={values.password}
              onChange={handleChange}
              sx={{ bgcolor: "#E8F0FE" }}
              inputProps={{ style: { fontSize: 17 } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.map((error, index) => {
              return (
                <Alert severity="error" key={index}>
                  {error.message}
                </Alert>
              );
            })}
          </Box>
          <Box marginTop={5} display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "#ff6d00",
                width: 160,
                marginBottom: "5px",
                fontSize: 17,
                fontWeight: "600",
              }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress color="inherit" size={32} />
              ) : (
                "Sign In"
              )}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end", // Align to the right
              marginTop: 2,
            }}
          >
            <Link
              to="/forgot-password" // Specify the link destination for the "Forgot Password?" page
              style={{
                textDecoration: "none",
                color: "#00796b",
                fontSize: 19,
              }}
            >
              Forgot Password?
            </Link>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: 2,
            }}
          >
            <span>
              Create account here{" "}
              <Link
                to="/register"
                style={{
                  textDecoration: "none",
                  color: "#00796b",
                  fontSize: 19,
                }}
              >
                Signup
              </Link>
            </span>
          </Box> */}
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </Box>
  );
};
export default SignIn;
