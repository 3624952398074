import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Popover,
  styled,
  TableCell,
  tableCellClasses,
  Grid
} from "@mui/material";
import Button from "@mui/material/Button";
import {
  DataGrid,
  GridToolbar
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useNavigate } from "react-router-dom";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import PopupState, {
  bindPopover,
  bindTrigger,
  bindToggle,
} from "material-ui-popup-state";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import {
  PENDING_CHECKOUTS,
  GET_SCHEDULES_PER_CUSTOMER,
  CHECKOUT_PER_CUSTOMER,
  EMPLOYEE_DATA,
  CHECKOUT_IMAGES,
} from "../../graphql/queries";
import { AUTH_ID } from "../../components/Authentication/constant";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";
import { APPROVED_CHECKOUT, DELETE_CHECKOUT, SEND_CHECKOUT_DATA } from "../../graphql/mutations";
import { useTranslation } from "react-i18next";
import PermMediaIcon from '@mui/icons-material/PermMedia';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { MAP_API_KEY } from "../Authentication/config";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DetailsIcon from '@mui/icons-material/Details';
import AdjustCheckout from "./checkout/AdjustCheckout";
import ApproveCheckout from "./checkout/ApproveCheckout";
import ErrorIcon from '@mui/icons-material/Error';
import {
  useJsApiLoader,
  GoogleMap,
  MarkerF
} from '@react-google-maps/api';
import swal from "sweetalert";
import DeleteIcon from "@mui/icons-material/Delete";

const StyledTableCell = styled(TableCell)(() => ({
  textAlign: "center",
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 700,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 250, sm: 500, md: 650 },
  bgcolor: "background.paper",
  border: "2px solid #000",
  // borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

const PendingCheckOuts = () => {

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: MAP_API_KEY,
    libraries: ['places'],
  });

  const navigate = useNavigate();
  const [checkOuts, setCheckOuts] = useState([]);
  const [checkOut, setCheckOut] = useState();
  const [schedulesData, setSchedulesData] = useState([]);
  const [empName, setEmpName] = useState([]);
  const [images, setImages] = useState([]);
  const [loadings, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [cusID, setCusID] = useState("");
  const [schID, setSchID] = useState("");
  const {t} = useTranslation('global');
  const [checkinCenter, setCheckinCenter] = useState({});
  const [checkoutCenter, setCheckoutCenter] = useState({});

  const [eachCheckoutData, setEachCheckoutData] = useState("");

  const handleChange = (event) => {
    setSchID(event.target.value);
  };

  ///////////>>>>>>>Query: Get Employee Data...................
  const { data: empData } = useQuery(EMPLOYEE_DATA, {
    variables: { parent_id: AUTH_ID },
    fetchPolicy: 'no-cache',
  });

  const [ApprovedCheckOUt, { data: approveData, loading: approveLoading }] =
    useMutation(APPROVED_CHECKOUT, {
      refetchQueries: [
        {
          schedule_id: null,
          query: PENDING_CHECKOUTS,
          awaitRefetchQueries: true,
        },
      ],
      onCompleted: () => {
        handleClose();
        navigate("/dashboard/companyDashboard/checkincheckout");
      },
    });

  const handleClick = async () => {
    await ApprovedCheckOUt({
      variables: {
        schedule_id: schID,
        checkout_id: cusID,
      },
    });
  };

  // State for approve checkout modal.
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  // State for adjust checkout modal.
  const [openAdj, setOpenAdj] = useState(false);
  const [openImages, setOpenImages] = useState(false);
  const [showLocations, setShowLocations] = useState(false);
  const handleCloseAdj = () => {
    setOpenAdj(false);
  };
  const handleCloseImages = () => {
    setOpenImages(false);
  };
  const handleCloseLocations = () => {
    setShowLocations(false);
  };

  //checkOuts data
  const { data, loading } = useQuery(PENDING_CHECKOUTS,  {
    fetchPolicy: 'no-cache', 
  });
  const columns = [
    {
      field: "customer",
      headerName: t('general.customer'),
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => `${params.row.customer.name}`,
    },
    {
      field: "employee",
      headerName: t('general.employee'),
      flex: 1,
      minWidth: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => `${params.row.employee.name}`,
    },
    {
      field: "date",
      headerName: t('general.date'),
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => {
        const date = new Date(params.row.end_time);
        const options = { year: "numeric", month: "numeric", day: "numeric" };
        return date.toLocaleDateString([], options);
      },
    },
    {
      field: "start_time",
      headerName: t('general.start_time'),
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => {
        const date = new Date(params.row.start_time);
        return date.toLocaleTimeString([], {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        });
      },
    },
    {
      field: "end_time",
      headerName: t('general.end_time'),
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => {
        const date = new Date(params.row.end_time);
        return date.toLocaleTimeString([], {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        });
      },
    },
    {
      field: "images",
      headerName: t('general.images'),
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.row.images.length) {
          return (
            <>
              <Link
                style={{
                  textDecoration: "none",
                  color: "gray",
                  hover: {
                    bgcolor: "gray",
                  },
                }}
                onClick={() => handleOpenImages(params.row.id)}
              >
                <PermMediaIcon style={{ cursor: 'pointer' }} />
              </Link>
            </>
          );
        } else {
          return t('general.no_image') ;
        }
      },
    },
    {
      field: "location",
      headerName: t('general.locations'),
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {

          return (
            <>
              <Link
                style={{
                  textDecoration: "none",
                  color: "gray",
                  hover: {
                    bgcolor: "gray",
                  },
                }}
                onClick={() => handleOpenLocations(params.row)}
              >
                <LocationOnIcon style={{ cursor: 'pointer' }} />
              </Link>
            </>
          );
      },
    },
    {
      field: "actions",
      headerName: t('general.actions'),
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => (
        <StyledTableCell>
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <div>
                {/* Action in Table Cell */}
                <MoreVertIcon
                  sx={{ color: "gray", fontSize: "28px" }}
                  {...bindTrigger(popupState)}
                />
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Box sx={{ display: "block" }}>
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "gray",
                        hover: {
                          bgcolor: "gray",
                        },
                      }}
                      onClick={() => handleOpenAdj(params.row.id)}
                    >
                      <ListItemButton {...bindToggle(popupState)}>
                        <ListItemIcon>
                          <EditIcon />
                        </ListItemIcon>
                        <ListItemText primary="Adjust checkout" />
                      </ListItemButton>
                    </Link>

                    <Divider width="100%" flexItem />
                    <ListItemButton
                      onClick={handleOpen(
                        params.row.customer.id,
                        params.row.id
                      )}
                    >
                      <ListItemIcon>
                        <AssignmentTurnedInIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="Approve checkout" />
                    </ListItemButton>

                    <Divider width="100%" flexItem />
                    <Link
                      to={`/dashboard/companyDashboard/checkoutdetail/${params.id}`}
                      style={{
                        textDecoration: "none",
                        color: "gray",
                      }}
                    >
                      <ListItemButton {...bindToggle(popupState)}>
                        <ListItemIcon>
                          <DetailsIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={ t('headings.checkout_details') } />
                      </ListItemButton>
                    </Link>

                    <Divider width="100%" flexItem />
                    <Link
                      onClick={ () => handleDeleteCheckout(params.id) }
                      style={{
                        textDecoration: "none",
                        color: "gray",
                      }}
                    >
                      <ListItemButton {...bindToggle(popupState)}>
                        <ListItemIcon>
                          <DeleteIcon color="error" />
                        </ListItemIcon>
                        <ListItemText primary={ t('checkouts.delete_checkout') } />
                      </ListItemButton>
                    </Link>

                  </Box>
                </Popover>
              </div>
            )}
          </PopupState>
        </StyledTableCell>
      ),
    },
  ];

  const [fetchData, { data: schedules }] = useLazyQuery(
    GET_SCHEDULES_PER_CUSTOMER
  );
  const [fetchCheckOutData, { data: checkOutData, loading: AdjLoading }] = useLazyQuery(
    CHECKOUT_PER_CUSTOMER
  );

  const [fetchCheckOutImages, { data: checkOutImages, loading: imagesLoading, error: errorLoadingImages }] = useLazyQuery(
    CHECKOUT_IMAGES,
    {
      fetchPolicy: "no-cache",
    }
  );

  const handleOpen = (customerId, rowID) => () => {
    setOpen(true);
    setCusID(rowID);
    fetchData({
      variables: {
        parent_id: AUTH_ID,
        customer_id: customerId,
      },
    });
  };

  // delete checkout mutation.
  const [deleteCheckout] = useMutation(DELETE_CHECKOUT);
  const handleDeleteCheckout = (id) => {
    swal({
      title: t('pop_ups.are_you_sure'),
      text: t('pop_ups.checkout_delete_confirm'),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteCheckout({
          variables: {
            id: id,
            company_id: AUTH_ID
          }
        })
          .then((response) => {
            console.log(response);
            if(response.data.deleteCheckout.success){

              const indexToDelete = checkOuts.findIndex((customer) => customer.id === id);
              if (indexToDelete !== -1) {
                // Create a new array without the deleted row
                const updatedCheckouts = [...checkOuts];
                updatedCheckouts.splice(indexToDelete, 1);
                setCheckOuts(updatedCheckouts);
              }

              swal(t('pop_ups.checkout_deleted'), {
                icon: "success",
              });
            }else{
              swal(response.data.deleteCheckout.message, {
                icon: "error",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            swal(t('pop_ups.error_occurred'), {
              icon: "error",
            });
          });
      } else {
        swal(t('pop_ups.checkout_is_safe'));
      }
    });
  };

  const handleOpenAdj = (rowId) => {
    setOpenAdj(true);
    fetchCheckOutData({
      variables: {
        parent_id: AUTH_ID,
        id: rowId,
      },
    });
  };

  const handleOpenImages = (rowId) => {

    setImages([]);
    fetchCheckOutImages({
      variables: {
        id: rowId,
      },
    });
    setOpenImages(true);
  };

  const handleOpenLocations = (rowData) => {
    const rowLocation = rowData.location;
    const location = JSON.parse(rowLocation);

    setEachCheckoutData(rowData);
    
    setCheckinCenter({
        lat : parseFloat(location.checkInLatitude),
        lng: parseFloat(location.checkInLongitude)
    });

    setCheckoutCenter({
        lat : parseFloat(location.checkOutLatitude),
        lng: parseFloat(location.checkOutLongitude)
    });
    
    setShowLocations(true);
  };

  const formatDateTime = (dateTimeString) => {
    const inputTime = new Date(dateTimeString);
    const formattedTime = inputTime.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return formattedTime;
  };

    const formatTime = (timeString) => {
        const options = { hour: "numeric", minute: "numeric", hour12: true };
        return new Date(timeString).toLocaleTimeString(undefined, options);
    };

  // Update data of adjustCheckout.......
  const [updateAdjustCheckOut] = useMutation(SEND_CHECKOUT_DATA, {
    refetchQueries: [
      {
        schedule_id: null,
        query: PENDING_CHECKOUTS,
        awaitRefetchQueries: true,
      },
    ],
    onCompleted: () => {
      handleCloseAdj();
      setLoading(false);
    },
  });

  useEffect(() => {
    try {
      setCheckOuts(data.pendingCheckOuts);
      setEmpName(empData.employees);
    } catch (error) {
      console.log(error.message);
    }
  }, [data, empData]);

  useEffect(() => {
    try {
      setCheckOut(checkOutData?.checkOut);
    } catch (error) {
      console.log(error.message);
    }
  }, [checkOutData]);

  useEffect(() => {
    try {
      if(errorLoadingImages){
        console.log("errorLoadingImages", errorLoadingImages);
      }
      setImages(checkOutImages.checkOutImages.images);
    } catch (error) {
      console.log("Error:", error.message);
    }
  }, [data, checkOutImages, errorLoadingImages]);

  useEffect(() => {
    try {
      setSchedulesData(schedules.schedules);
    } catch (error) {
      console.log(error.message);
    }
  }, [schedules]);

  const handleInputChange = (dateName, dateValue) => {
    console.log("dateName:", dateName);
    console.log("dateValue:", dateValue.target.value);

    if (dateName === "startTime") {
      setCheckOut({
        ...checkOut,
        start_time: dayjs(new Date(dateValue.toString())).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      });
    } else if (dateName === "endTime") {
      setCheckOut({
        ...checkOut,
        end_time: dayjs(new Date(dateValue.toString())).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      });
    } else if (dateName === "employee") {
      setCheckOut({
        ...checkOut,
        employee: {
          ...checkOut.employee,
          id: dateValue.target.value,
        },
      });
    }
    console.log("Updated Checkout:", checkOut);
    // console.log(dateName, " = ", dateValue.target.value);
    // setCheckOut({
    //   ...checkOut,
    //   employee: {
    //     ...checkOut.employee,
    //     name: e.target.value,
    //   },
    // })
  };

  const handleSaveAdjustClick = async () => {
    setLoading(true);
    await updateAdjustCheckOut({
      variables: {
        id: checkOut?.id,
        start_time: checkOut?.start_time,
        end_time: checkOut.end_time,
        employee_id: checkOut.employee.id,
        customer_id: checkOut.customer.id,
        company_id: AUTH_ID,
      },
    });
  };
  return (
    <>
      <h2 style={{ marginTop: "10px"  }}>{ t('pending_checkouts.pending_checkouts') }</h2>
      <Box
        sx={{
          height: 700,
          width: "100%",
          marginTop:"20px",
          "& .super-app-theme--header": {
            fontWeight: "bolder",
            fontSize: 18,
          },
        }}
      >
        <DataGrid
          // autoHeight
          loading={loading}
          columns={columns}
          rows={checkOuts}
          rowHeight={60}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          slots={{ toolbar: GridToolbar }}
          localeText={{
            toolbarExport: t('data_grid.export'),
            toolbarExportCSV : t('data_grid.toolbar_export_csv'),
            toolbarExportPrint : t('data_grid.toolbar_export_print'),
            toolbarFilters : t('data_grid.toolbar_filters'),
            toolbarColumns : t('data_grid.toolbar_columns'),
            columnMenuSortAsc : t('data_grid.column_menu_sort_asc'),
            columnMenuSortDesc: t('data_grid.column_menu_sort_desc'),
            columnMenuHideColumn : t('data_grid.column_menu_hide_column'),
            columnMenuManageColumns : t('data_grid.column_menu_manage_columns'),
            toolbarDensity: t('data_grid.toolbar_density_label'),
            toolbarDensityLabel: t('data_grid.toolbar_density_label'),
            toolbarDensityCompact: t('data_grid.toolbar_density_compact'),
            toolbarDensityStandard: t('data_grid.toolbar_density_standard'),
            toolbarDensityComfortable: t('data_grid.toolbar_density_comfortable'),
            toolbarQuickFilterPlaceholder : t('data_grid.toolbar_quick_filter_label'),
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
            pagination: {
              labelRowsPerPage: t('data_grid.rows_per_page_options'),
              labelDisplayedRows : ({ from, to, count }) => {
                    const countText = count !== -1 ? count : `${t('general.more_than')} ${to}`;
                    return `${from}–${to} ${t('general.of')} ${countText}`;
                } 
            }
          }}
        />
      </Box>

      <AdjustCheckout 
        open={openAdj}
        onClose={() => setOpenAdj(false)}
        checkOut={checkOut}
        empName={empName}
        handleInputChange={handleInputChange}
        handleSaveAdjustClick={handleSaveAdjustClick}
        loadings={loadings}
        AdjLoading={AdjLoading}
      />

       {/* Render the ApproveCheckout component */}
       <ApproveCheckout
        open={open}
        handleClose={handleClose}
        schedulesData={schedulesData}
        handleChange={handleChange}
        handleClick={handleClick}
        approveLoading={approveLoading}
        setLoading={setLoading}
      />

      {/* Preview images */}
      <Modal open={openImages} onClose={handleCloseImages}>
      <Box sx={{ ...style, overflowY: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {images?.length > 0 ? (
            <>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                sx={{ mt: 2, mb: 4 }}
              >
                { t('headings.checkout_images') }
              </Typography>

              <ImageList sx={{ width: 'auto', height: 450 }}>
                {images.map((item, index) => (
                  <ImageListItem key={index}>
                    <img
                      src={`data:image/jpeg;base64,${item.image}`}
                      srcSet={`data:image/jpeg;base64,${item.image}`}
                      alt={`Work History ${index}`}
                      loading="lazy"
                    />

                    <ImageListItemBar
                        sx={{
                          background:
                            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                        }}
                        title={ item.uploaded_from ? `${t('general.uploaded_from')} ${item.uploaded_from}` : "" }
                        position="top"
                        actionPosition="left"
                    />
                     
                    <ImageListItemBar

                      title={ item.creation_time ? `${t('general.captured_at')} ${formatDateTime(item.creation_time)}` : "" }
                     
                      subtitle={`${t('general.uploaded_at')} ${formatDateTime(item.uploaded_time)}`}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
              <Box sx={{ mt: 3, mb: 2, textAlign: "center" }}>
                <Button
                  onClick={() => {
                    handleCloseImages();
                  }}
                  variant="contained"
                  sx={{
                    minWidth: "150px",
                    p: 1,
                    m: 1,
                    bgcolor: "#ff9800",
                    ":hover": { bgcolor: "#ff9800" },
                  }}
                >
                  { t('general.close') }
                </Button>
              </Box>
            </>
          ) : (
            <>
               <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                sx={{ mt: 2, mb: 4 }}
              >
                { t('headings.checkout_images') }
              </Typography>

              {
                !errorLoadingImages ? (
                  <CircularProgress color="primary" size={50} sx={{ mt: 5 }} />
                ) : (
                  <>
                    <ErrorIcon color="error" fontSize="large" sx={{ mb: 2 }} />
                    { t('errors.error_loading_images') }
                  </>
                )
              }

              
              <Box sx={{ mt: 5, mb: 2, textAlign: "center" }}>
                <Button
                  onClick={() => {
                    handleCloseImages();
                  }}
                  variant="contained"
                  sx={{
                    minWidth: "150px",
                    p: 1,
                    m: 1,
                    bgcolor: "#ff9800",
                    ":hover": { bgcolor: "#ff9800" },
                  }}
                >
                  { t('general.close') }
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>

       {/* Show location */}
      <Modal open={showLocations} onClose={handleCloseLocations}>
        <Box sx={{ ...style, overflowY: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {isLoaded ? (
            <>
              {/* <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                sx={{ mt: 2, mb: 4 }}
              >
                { t('general.locations') }
              </Typography> */}


              <Grid container spacing={2} sx={{ mb:2 }}>
                <Grid item xs={12} sm={12} md={6}>
                <Typography>
                    {t('general.employee')}: {eachCheckoutData?.employee?.name}
                  </Typography>
                  <Typography>
                  {t('general.start_time')}: {formatTime(eachCheckoutData?.start_time)}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Typography>
                  {t('general.customer')}: {eachCheckoutData?.customer?.name}
                  </Typography>
                  <Typography>
                  {t('general.end_time')}: {formatTime(eachCheckoutData?.end_time)}
                  </Typography>
                </Grid>
              </Grid>

              {/* <Divider width="100%" flexItem /> */}

              <Grid container spacing={2}>
                {/* Check in location */}
                <Grid item xs={12} sm={12} md={6}>
                    <Typography variant="h6" gutterBottom>
                        {t('checkouts.checkin_location')}
                    </Typography>
                    {(checkinCenter?.lat) ? (
                        <GoogleMap
                            center={checkinCenter}
                            zoom={15}
                            mapContainerStyle={{ width: '100%', height: '500px' }}
                        >
                            <MarkerF 
                            showInfoWindow={true}
                            position={checkinCenter} />
                        </GoogleMap>
                    ) : (
                        <CircularProgress color="primary" size={50} sx={{ mt: 5 }} />
                    )}
                </Grid>

                {/* Checkout location */}
                <Grid item xs={12} sm={12} md={6}>
                    <Typography variant="h6" gutterBottom>
                        {t('checkouts.checkout_location')}
                    </Typography>
                    {(checkoutCenter?.lat) ? (
                        <GoogleMap
                            center={checkoutCenter}
                            zoom={15}
                            mapContainerStyle={{ width: '100%', height: '500px' }}
                        >
                            <MarkerF position={checkoutCenter} />
                        </GoogleMap>
                    ) : (
                        <CircularProgress color="primary" size={50} sx={{ mt: 5 }} />
                    )}
                </Grid>
              </Grid>
              
              <Box sx={{ mt: 3, mb: 2, textAlign: "center" }}>
                <Button
                  onClick={() => {
                    handleCloseLocations();
                  }}
                  variant="contained"
                  sx={{
                    minWidth: "150px",
                    p: 1,
                    m: 1,
                    bgcolor: "#ff9800",
                    ":hover": { bgcolor: "#ff9800" },
                  }}
                >
                  { t('general.close') }
                </Button>
              </Box>
            </>
          ) : (
            <>
               <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                sx={{ mt: 2, mb: 4 }}
              >
                { t('general.locations') }
              </Typography>
              <CircularProgress color="primary" size={50} sx={{ mt: 5 }} />
              
              <Box sx={{ mt: 5, mb: 2, textAlign: "center" }}>
                <Button
                  onClick={() => {
                    handleCloseLocations();
                  }}
                  variant="contained"
                  sx={{
                    minWidth: "150px",
                    p: 1,
                    m: 1,
                    bgcolor: "#ff9800",
                    ":hover": { bgcolor: "#ff9800" },
                  }}
                >
                  { t('general.close') }
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>

      
    </>
  );
};

export default PendingCheckOuts;
