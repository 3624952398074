import React, { useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import Title from "./Title";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
// import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { tableCellClasses } from "@mui/material/TableCell";
import Popover from "@mui/material/Popover";
import PopupState, {
  bindTrigger,
  bindPopover,
  bindToggle,
} from "material-ui-popup-state";
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  Modal,
  OutlinedInput,
  Divider,
  styled,
  Typography,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Input,
} from "@mui/material";
import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useMutation, useQuery } from "@apollo/client";
import swal from "sweetalert";
import { COMPANIES_DATA } from "../../graphql/queries";
import { UPDATE_OR_CREATE_USER, DELETE_COMPANY } from "../../graphql/mutations";
import { AUTH_ID } from "../Authentication/constant";
import CircularProgress from "@mui/material/CircularProgress";
import BasicSnackBar from "../company/BasicSnackBar";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(() => ({
  textAlign: "center",
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 700,
  },
}));
const style = {
  minHeight: "auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};
export default function Companies() {
  const {t} = useTranslation("global");
  const [companies, setCompanies] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  //----------- above two sorting states-----------//
  const [modalOpen, setmodalOpen] = useState(false);
  const initialValues = {
    name: "",
    email: "",
    address: "",
    // password: "",
    contact: "",
    role_id: 2,
  };
  const [newCompany, setNewCompany] = useState(initialValues);
  const [errors, setErrors] = useState(false);
  //------------------------Sucess Alert State......
  const [openAlert, setOpenAlert] = useState(false);
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  ////////////////////Query for get Companies data/////////////
  const { data, loading } = useQuery(COMPANIES_DATA, {
    variables: { parent_id: AUTH_ID },
    fetchPolicy:"no-cache"
  });
  useEffect(() => {
    try {
      setCompanies(data.companies);
    } catch (error) {
      console.log(error.message);
    }
  }, [data]);

  const [CreateUser, { loading: cmpLoading }] = useMutation(
    UPDATE_OR_CREATE_USER,
    {
      refetchQueries: [
        {
          query: COMPANIES_DATA,
          variables: { parent_id: AUTH_ID },
          awaitRefetchQueries: true,
        },
      ],
    }
  );
  // delete Company Mutation.
  const [deleteUser] = useMutation(DELETE_COMPANY);
  const removeCompany = (id) => {
    deleteUser({
      variables: {
        id: id,
      },
      refetchQueries: [
        {
          query: COMPANIES_DATA,
          variables: { parent_id: AUTH_ID },
          awaitRefetchQueries: true,
        },
      ],
    });
  };

  const onValueChange = (e) => {
    let data = { ...newCompany };
    let name = e.target.name;
    let val = e.target.value;
    if (
      name === "name" ||
      name === "email" ||
      name === "contact" ||
      name === "address" ||
      // name === "password" ||
      name === "role_id"
    ) {
      data = { ...data, [name]: val };
    }
    setNewCompany(data);
    // setNewCompany({ ...newCompany, [e.target.name]: e.target.value });
  };

  const handleClick = async () => {
    if (
      newCompany.name &&
      newCompany.email &&
      newCompany.contact &&
      newCompany.address 
      // newCompany.password
    ) {
      await CreateUser({
        variables: {
          name: newCompany.name,
          email: newCompany.email,
          contact: newCompany.contact,
          address: newCompany.address,
          // password: newCompany.password,
          role_id: 2,
          parent_id: AUTH_ID,
        },
      });
      console.log("newCompany", companies);
      console.log("newCompany", newCompany);
      setmodalOpen(false);
      setNewCompany("");
      setOpenAlert(true);
      setErrors(false);
    } else {
      setErrors(true);
      setmodalOpen(true);
    }
  };
  // const handleDisabled = () => {
  //   alert("disable pending");
  // };
  const handleOpen = () => setmodalOpen(true);
  const handleClose = () => setmodalOpen(false);

  // Column Def of Customer DataGrid.
  const columns = [
    {
      field: "name",
      headerName: t('general.name'),
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "email",
      headerName: t('general.email'),
      minWidth: 230,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "contact",
      headerName: t('general.contact'),
      minWidth: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "address",
      headerName: t('general.address'),
      minWidth: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "actions",
      headerName: t('general.actions'),
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => (
        <StyledTableCell>
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <div>
                {/* Action in Table Cell */}
                <MoreVertIcon
                  sx={{ color: "gray", fontSize: "28px" }}
                  {...bindTrigger(popupState)}
                />
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Box sx={{ display: "block" }}>
                    <Link
                      to={`/dashboard/adminDashboard/editCompany/${params.id}`}
                      style={{
                        textDecoration: "none",
                        color: "gray",
                        hover: {
                          bgcolor: "gray",
                        },
                      }}
                    >
                      <ListItemButton {...bindToggle(popupState)}>
                        <ListItemIcon>
                          <EditIcon />
                        </ListItemIcon>
                        <ListItemText primary={ t('companies.update_company') } />
                      </ListItemButton>
                    </Link>
                    <Divider width="100%" flexItem />
                    <Link
                      onClick={() => {
                        swal({
                          title: t('pop_ups.are_you_sure'),
                          text: t('pop_ups.company_delete_confirm'),
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then((willDelete) => {
                          if (willDelete) {
                            removeCompany(params.id)
                              ? swal(t('pop_ups.company_is_safe'))
                              : swal(
                                  t('pop_ups.company_deleted'),
                                  {
                                    icon: "success",
                                  }
                                );
                          } else {
                            swal(t('pop_ups.company_is_safe'));
                          }
                        });
                      }}
                      style={{
                        textDecoration: "none",
                        color: "gray",
                      }}
                    >
                      <ListItemButton {...bindToggle(popupState)}>
                        <ListItemIcon>
                          <DeleteIcon color="error" />
                        </ListItemIcon>
                        <ListItemText primary={ t('companies.delete_company') } />
                      </ListItemButton>
                    </Link>
                  </Box>
                </Popover>
              </div>
            )}
          </PopupState>
        </StyledTableCell>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Title>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            marginBottom: "20px",
          }}
        >
          <Button
            onClick={handleOpen}
            variant="contained"
            sx={{ bgcolor: "#F16101", ":hover": { bgcolor: "#F16101" } }}
          >
            { t('companies.add_company') }
          </Button>
        </Box>
      </Title>
      {/* Companies DataGrid */}
      <Box
        sx={{
          height: 400,
          width: "100%",
          "& .super-app-theme--header": {
            fontWeight: "700",
            fontSize: 18,
          },
          "& .super-app-theme--cell": {
            fontSize: 18,
          },
        }}
      >
        <DataGrid
          loading={loading}
          columns={columns}
          rows={companies}
          rowHeight={60}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 30]}
          localeText={{
            toolbarExport: t('data_grid.export'),
            toolbarExportCSV : t('data_grid.toolbar_export_csv'),
            toolbarExportPrint : t('data_grid.toolbar_export_print'),
            toolbarFilters : t('data_grid.toolbar_filters'),
            toolbarColumns : t('data_grid.toolbar_columns'),
            columnMenuSortAsc : t('data_grid.column_menu_sort_asc'),
            columnMenuSortDesc: t('data_grid.column_menu_sort_desc'),
            columnMenuHideColumn : t('data_grid.column_menu_hide_column'),
            columnMenuManageColumns : t('data_grid.column_menu_manage_columns'),
            toolbarDensity: t('data_grid.toolbar_density_label'),
            toolbarDensityLabel: t('data_grid.toolbar_density_label'),
            toolbarDensityCompact: t('data_grid.toolbar_density_compact'),
            toolbarDensityStandard: t('data_grid.toolbar_density_standard'),
            toolbarDensityComfortable: t('data_grid.toolbar_density_comfortable'),
          }}
          slotProps={{
            pagination: {
              labelRowsPerPage: t('data_grid.rows_per_page_options'),
              labelDisplayedRows : ({ from, to, count }) => {
                    const countText = count !== -1 ? count : `${t('general.more_than')} ${to}`;
                    return `${from}–${to} ${t('general.of')} ${countText}`;
                } 
            }
          }}
        />
      </Box>
      {/* Add Company Modals Start */}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" textAlign="center">
            { t('companies.add_company_details') }
          </Typography>
          {/* AddCompany Component */}
          <Box component="form">
            <FormControl fullWidth>
              <InputLabel sx={{ m: 1 }} htmlFor="companyName">
              { t('general.name') }
              </InputLabel>
              <OutlinedInput
                id="companyName"
                sx={{ m: 1 }}
                fullWidth
                name="name"
                value={newCompany.name}
                onChange={(e) => onValueChange(e)}
                label="Company Name"
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel sx={{ m: 1 }} htmlFor="companyEmail">
              { t('general.email') }
              </InputLabel>
              <OutlinedInput
                fullWidth
                id="companyEmail"
                sx={{ m: 1 }}
                name="email"
                value={newCompany.email}
                onChange={(e) => onValueChange(e)}
                label="company Email"
              />
            </FormControl>
            {/* <FormControl fullWidth>
              <InputLabel sx={{ m: 1 }} htmlFor="companyPassword">
                companyPassword
              </InputLabel>
              <OutlinedInput
                fullWidth
                type="password"
                id="companyPassword"
                sx={{ m: 1 }}
                name="password"
                value={newCompany.password}
                onChange={(e) => onValueChange(e)}
                label="company password"
              />
            </FormControl> */}
            <FormControl fullWidth>
              <InputLabel sx={{ m: 1 }} htmlFor="companyContact">
              { t('general.contact') }
              </InputLabel>
              <OutlinedInput
                fullWidth
                id="companyContact"
                sx={{ m: 1 }}
                name="contact"
                value={newCompany.contact}
                onChange={(e) => onValueChange(e)}
                label="company contact"
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel sx={{ m: 1 }} htmlFor="companyAddress">
              { t('general.address') }
              </InputLabel>
              <OutlinedInput
                fullWidth
                id="companyAddress"
                sx={{ m: 1 }}
                name="address"
                value={newCompany.address}
                onChange={(e) => onValueChange(e)}
                label="company address"
              />
            </FormControl>
            <Input type="hidden" name="role_id" value={newCompany.role_id} />
            {errors ? (
              <Alert severity="error">Each field must be filled!</Alert>
            ) : (
              ""
            )}
            <Box sx={{ mt: 3, mb: 2, textAlign: "center" }}>
              <Button
                onClick={() => {
                  handleClose();
                  setNewCompany("");
                  setErrors(false);
                }}
                variant="contained"
                sx={{
                  minWidth: "150px",
                  p: 1,
                  m: 1,
                  bgcolor: "#ff9800",
                  ":hover": { bgcolor: "#ff9800" },
                }}
              >
                 { t('general.cancel') }
              </Button>
              <Button
                sx={{
                  minWidth: "150px",
                  p: 1,
                  m: 1,
                  bgcolor: "#F16101",
                  ":hover": { bgcolor: "#F16101" },
                }}
                variant="contained"
                onClick={() => handleClick()}
                disabled={cmpLoading}
              >
                {cmpLoading ? (
                  <CircularProgress size={24} color="primary" />
                ) : (
                  t('general.add')
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* Add Company Modals End */}
      {/* Success Alert Message */}
      <BasicSnackBar
        open={openAlert}
        onClose={handleAlertClose}
        severity="success"
        message={ t('pop_ups.company_added') }
      />
    </React.Fragment>
  );
}
