import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import logoImage from "../../assets/logo.png";
// import { Button } from "@mui/material";
// import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  // const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "#ffffff" }}>
        <Toolbar>
          <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
            <img
              src={logoImage}
              alt="logo"
              height="25"
              style={{ marginRight: "10px" }}
            />
          </Box>
          {/* <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Button
              variant="contained"
              sx={{
                minWidth: 100,
                textTransform: "none",
                backgroundColor: "#ff6d00",
                ":hover": { bgcolor: "#ffa726" },
              }}
              onClick={() => navigate("/")}
            >
              SignIn
            </Button>
            <Button
              variant="contained"
              sx={{
                minWidth: 100,
                textTransform: "none",
                backgroundColor: "#ff6d00",
                ":hover": { bgcolor: "#ffa726" },
              }}
              onClick={() => navigate("/register")}
            >
              SignUp
            </Button>
          </Box> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
