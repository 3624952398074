import { useMutation, useQuery } from "@apollo/client";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CUSTOMERS_EMPLOYEES,
  GET_SCHEDULES_PER_CUSTOMER,
} from "../../../graphql/queries";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { CREATE_SCHEDULES } from "../../../graphql/mutations";
import { AUTH_ID } from "../../Authentication/constant";
import BasicSnackBar from "../BasicSnackBar";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomerDetail = () => {
  const navigate = useNavigate();
  const {t} = useTranslation('global');
  const { id } = useParams();
  const [checkDays, setCheckDays] = useState([]);
  const [employees, setEmployees] = useState([]);
  const userTemplate = {
    start_time: dayjs("h:mm A"),
    end_time: dayjs("h:mm A"),
    employee_id: [],
    days: [],
  };

  const [users, setUsers] = useState([userTemplate]);
  const [customer_id, setCustomer_id] = useState(id);
  const [empName, setEmpName] = useState([]);
  const [selectDays, setSelectDays] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const { data } = useQuery(CUSTOMERS_EMPLOYEES, {
    variables: { parent_id: AUTH_ID },
  });
  useEffect(() => {
    try {
      const employeesNames = data.employees.map((employee) => {
        return {
          id: employee.id,
          name: employee.name,
        };
      });
      setEmpName(employeesNames);
      const days = data.days.map((day) => {
        return {
          id: day.id,
          day: day.name,
        };
      });
      setSelectDays(days);
    } catch (error) {
      console.log("employeesNames:", error.message);
    }
  }, [data]);
  //-----------CreateSchedule-------------
  const [createSchedule] = useMutation(CREATE_SCHEDULES, {
    refetchQueries: [
      {
        query: GET_SCHEDULES_PER_CUSTOMER,
        variables: { parent_id: AUTH_ID, customer_id: customer_id },
        awaitRefetchQueries: true,
      },
    ],
    onCompleted: ({ createSchedule }) => {
      setOpenAlert(true);
      setTimeout(() => {
        navigate(`/dashboard/companyDashboard/customer-detail/${customer_id}`);
      }, 3000);
      setLoading(false);
    },
  });

  //---------Add More Schedule Method
  const handleAddMore = () => {
    setUsers([...users, userTemplate]);
  };

  //--------------onChange method
  const handleInputChange = (dateName, dateValue, index) => {
    if (dateName === "startTime") {
      const updateUsers = users.map((user, i) =>
        index === i
          ? Object.assign(user, {
              start_time: dayjs(new Date(dateValue.toString())).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
            })
          : user
      );
     
      setUsers(updateUsers);
    } else if (dateName === "endTime") {
      const updateUsers = users.map((user, i) =>
        index === i
          ? Object.assign(user, {
              end_time: dayjs(new Date(dateValue.toString())).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
            })
          : user
      );

      setUsers(updateUsers);
    } else if (dateName === "employee_id") {
      const updateUsers = users.map((user, i) =>
        index === i
          ? Object.assign(user, {
              [dateValue.target.name]: dateValue.target.value,
            })
          : user
      );
      setUsers(updateUsers);
      const labelEmp = users?.map((user, i) => {
        if (index === i) {
          const preEmps = [...employees];
          const filterEmps = empName?.filter((emp) =>
            dateValue.target.value.includes(emp.id)
          );
          preEmps[index] = filterEmps;
          setEmployees(preEmps);
        }
      });
    } else {
      const updateUsers = users.map((user, i) =>
        index === i
          ? Object.assign(user, {
              [dateValue.target.name]: dateValue.target.value,
            })
          : user
      );
      setUsers(updateUsers);
      const labelDays = users?.map((user, i) => {
        if (index === i) {
          const preDays = [...checkDays];
          const filterDay = selectDays?.filter((day) =>
            dateValue.target.value.includes(day.id)
          );
          preDays[index] = filterDay;
          setCheckDays(preDays);
          return filterDay;
        } else {
          console.log("filter error in onchange method of days");
        }
      });
    }
  };
  //===============Delete User Button
  const removeUser = (index) => {
    if (users.length > 1) {
      const filteredUsers = [...users];
      filteredUsers.splice(index, 1);
      setUsers(filteredUsers);
    }
  };

  //------------Submit Form Data Method
  // console.log("employees1", employees);
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Users", users);
    if (customer_id && users) {
      setLoading(true);
      await createSchedule({
        variables: {
          customer_id: customer_id,
          schedules: users,
        },
      });
    }
  };
  return (
    <React.Fragment>
      <Box>
        <ArrowBackIcon
          sx={{ color: "gray", marginBottom: "15px", fontSize: "28px" }}
          onClick={() =>
            navigate(
              `/dashboard/companyDashboard/customer-detail/${customer_id}`
            )
          }
        />
        <Box display={"flex"} flexDirection="column" justifyContent="center">
          <Typography variant="h6" gutterBottom>
            { t('schedules.add_schedule_detail') }
          </Typography>
          <Box
            component={"form"}
            onSubmit={handleSubmit}
            sx={{ paddingX: 2, boxShadow: 2 }}
          >
            <Box
              sx={{
                justifyContent: "space-between",
                marginY: 3,
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%" },
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <Button
                  sx={{
                    height: 40,
                    bgcolor: "#F16101",
                    ":hover": { bgcolor: "#F16101" },
                  }}
                  onClick={handleAddMore}
                  variant="contained"
                  color="success"
                >
                  { t('schedules.add_more_schedule') }
                </Button>
              </Box>
              <Input type="hidden" name="customer_id" value={customer_id} />
            </Box>
            {users
              ? users.map((user, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Divider sx={{ marginTop: 4 }} />
                      <Divider sx={{ marginBottom: 2 }} />
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "left",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: { xs: "100%" },
                            display: "flex",
                            alignItems: "flex-end",
                            marginBottom: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              width: { sm: 160, md: 140 },
                              display: { xs: "none", sm: "flex" },
                              fontSize: 20,
                              marginRight: 1,
                            }}
                          >
                             {t('days.days')}:
                          </Typography>
                          <FormControl fullWidth>
                            <InputLabel id="demo-multiple-checkbox-label">
                            {t('days.days')}
                            </InputLabel>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              label="Select Days"
                              required
                              name="days"
                              multiple
                              value={user?.days}
                              onChange={(date) =>
                                handleInputChange("days", date, index)
                              }
                              input={<OutlinedInput label="Select Days" />}
                              renderValue={(selected) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                  }}
                                >
                                  {selected?.map((days, i) => {
                                    // console.log("Days", days);
                                    return (
                                      <Chip
                                        key={checkDays[index][i].id}
                                        label={checkDays[index][i]?.day}
                                      />
                                    );
                                  })}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {selectDays.map((item) => {
                                // console.log(item.id, "=", item.day);
                                return (
                                  <MenuItem key={item.id} value={item.id}>
                                    <Checkbox
                                      checked={user?.days.indexOf(item.id) > -1}
                                    />
                                    <ListItemText primary={item.day} />
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box
                          width="100%"
                          sx={{
                            display: { xs: "block", md: "flex" },
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          <Box
                            sx={{
                              width: { xs: "100%", md: "48%" },
                              display: "flex",
                              justifyContent: "left",
                              alignItems: "flex-end",
                              gap: 0,
                            }}
                          >
                            <Typography
                              sx={{
                                width: { sm: 160, md: 140 },
                                fontSize: 20,
                                marginRight: 1,
                              }}
                            >
                              {t('general.from')}:
                            </Typography>
                            <LocalizationProvider
                              fullWidth
                              dateAdapter={AdapterDayjs}
                            >
                              <TimePicker
                                label={t('general.from')}
                                value={user?.start_time}
                                name="startTime"
                                onChange={(date) =>
                                  handleInputChange("startTime", date, index)
                                }
                                renderInput={(params) => (
                                  <TextField fullWidth {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          </Box>
                          <Box
                            sx={{
                              width: { xs: "100%", md: "48%" },
                              marginTop: { xs: 2, md: 0 },
                              display: "flex",
                              justifyContent: "left",
                              alignItems: "flex-end",
                              gap: 0,
                            }}
                          >
                            <Typography
                              sx={{
                                width: { sm: 160, md: 58 },
                                fontSize: 20,
                                marginRight: 1,
                                textAlign: { xs: "left", md: "right" },
                              }}
                            >
                              {t('general.to')}:
                            </Typography>
                            <LocalizationProvider
                              fullWidth
                              dateAdapter={AdapterDayjs}
                            >
                              <TimePicker
                                label={t('general.to')}
                                value={user?.end_time}
                                name="endTime"
                                onChange={(date) =>
                                  handleInputChange("endTime", date, index)
                                }
                                renderInput={(params) => (
                                  <TextField fullWidth {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          </Box>
                        </Box>
                        {/* employeeId */}
                        <Box
                          width="100%"
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            gap: 2,
                            marginTop: 1,
                          }}
                        >
                          <Box
                            sx={{
                              width: { xs: "100%", md: "48%" },
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "flex-end",
                            }}
                          >
                            <Typography
                              sx={{
                                display: { xs: "none", sm: "flex" },
                                fontSize: 20,
                                marginRight: 1,
                                width: { sm: 160, md: 140 },
                              }}
                            >
                              { t('employees.employees') }:
                            </Typography>
                            <FormControl fullWidth>
                              <InputLabel id="schedule-employee-name">
                              { t('employees.employees') }
                              </InputLabel>
                              <Select
                                labelId="schedule-employee-name"
                                id="schedule-employee"
                                label="Employee Name"
                                required
                                name="employee_id"
                                multiple
                                value={user?.employee_id}
                                onChange={(date) =>
                                  handleInputChange("employee_id", date, index)
                                }
                                input={
                                  <OutlinedInput label="Select Employee ID" />
                                }
                                // renderValue={(selected) => selected.join(", ")}
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected?.map((emps, i) => {
                                      // console.log("employees2", employees);
                                      return (
                                        <Chip
                                          key={employees[index][i].id}
                                          label={employees[index][i]?.name}
                                        />
                                      );
                                    })}
                                  </Box>
                                )}
                                MenuProps={MenuProps}
                              >
                                {empName?.map((item) => {
                                  // console.log( user?.employee_id);
                                  // console.log(item);
                                  return (
                                    <MenuItem key={item.id} value={item.id}>
                                      <Checkbox
                                        checked={
                                          user?.employee_id.indexOf(item.id) >
                                          -1
                                        }
                                      />
                                      <ListItemText primary={item.name} />
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Box>
                          <Box
                            sx={{
                              width: { xs: "100%", md: "48%" },
                              marginTop: { xs: 2, md: 0 },
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <IconButton onClick={() => removeUser(index)}>
                              <CloseIcon color="error" fontSize="large" />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </React.Fragment>
                  );
                })
              : "Loading..."}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 3,
                marginBottom: 2,
              }}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: "#F16101",
                  ":hover": { bgcolor: "#F16101" },
                  width: { xs: 200 },
                  fontSize: { xs: 16 },
                }}
                disabled={loading}
              >
                {loading ? "Adding..." : t('general.add')}
              </Button>
            </Box>
          </Box>
          {/* form Box end */}
        </Box>
      </Box>
      <BasicSnackBar
        open={openAlert}
        onClose={handleAlertClose}
        severity="success"
        message="schedule added successfully!"
      />
    </React.Fragment>
  );
};

export default CustomerDetail;
