import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import QRCode from 'qrcode';
import { useTranslation } from "react-i18next";

const QRCodeBox = ({ qrCodeRef, checkInQRcodeData, onDataUrlChange }) => {
  const { t } = useTranslation('global');
  const [qrCodeURL, setQRCodeURL] = useState('');

  useEffect(() => {
    if (checkInQRcodeData) {
      // Generate the QR code URL
      QRCode.toDataURL(checkInQRcodeData, (err, url) => {
        if (err) {
          console.error(err);
        } else {
          setQRCodeURL(url);
          onDataUrlChange(url);
        }
      });
    }
  }, [checkInQRcodeData, onDataUrlChange]);

  return (
    <Box
      ref={qrCodeRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          alignSelf: "center",
          maxWidth: 300,
          width: "100%",
        }}
      >
        {(checkInQRcodeData && qrCodeURL) ? (
          <img src={qrCodeURL} alt="QR Code" style={{ width:300, height:300, alignItems: "center" }}/>
        ) : (
          <p style={{ textAlign: "center" }}>{t('general.generating_qrcode')}</p>
        )}
      </Box>
    </Box>
  );
};

export default QRCodeBox;
