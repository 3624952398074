import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  createTheme,
  FormControl,
  Input,
  InputLabel,
  OutlinedInput,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_OR_CREATE_USER } from "../../graphql/mutations";
import { COMPANIES_DATA, GET_COMPANY } from "../../graphql/queries";
// import CircularProgress from "@mui/material/CircularProgress";
import { AUTH_ID } from "../Authentication/constant";
import { useTranslation } from "react-i18next";

const theme = createTheme();
const EditCompany = () => {
  const { id } = useParams();
  const [t, i18n] = useTranslation("global");
  const nevigate = useNavigate();
  const [editCompanyData, setEditCompanyData] = useState({
    name: "",
    email: "",
    contact: "",
    address: "",
    // password: "",
    role_id: 2,
  });
  const [updateUser] = useMutation(UPDATE_OR_CREATE_USER, {
    onCompleted: ({ updateUser }) => {
      console.log("login", updateUser);
    },
    refetchQueries: [
      {
        query: COMPANIES_DATA,
        variables: { parent_id: AUTH_ID },
        awaitRefetchQueries: true,
      },
    ],
  });
  ///////Get Company Query.....
  const { data } = useQuery(GET_COMPANY, {
    variables: { id: id },
  });
  useEffect(() => {
    try {
      setEditCompanyData(data.user);
    } catch (error) {
      console.log(error.message);
    }
  }, [data]);

  const onValueChange = (e) => {
    let newData = { ...editCompanyData };
    let name = e.target.name;
    let val = e.target.value;
    if (
      name === "name" ||
      name === "email" ||
      name === "contact" ||
      // name === "password" ||
      name === "address" ||
      name === "role_id"
    ) {
      newData = { ...newData, [name]: val };
    }
    setEditCompanyData(newData);
  };

  const updateData = () => {
    setEditCompanyData({ ...editCompanyData });
    updateUser({
      variables: {
        id: id,
        name: editCompanyData.name,
        email: editCompanyData.email,
        contact: editCompanyData.contact,
        address: editCompanyData.address,
        // password: editCompanyData.password,
        role_id: parseInt(editCompanyData.role.id),
      },
    });
    nevigate("/dashboard/adminDashboard/companies");
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        {editCompanyData ? (
          <Box
            sx={{
              textAlign: "center",
              boxShadow: 2,
              paddingTop: "35px",
              paddingBottom: "12px",
              marginTop: "8%",
            }}
          >
            <Typography variant="h6" textAlign="center" sx={{ m: 3 }}>
             { t('companies.edit_company_details') }
            </Typography>
            <Input
              type="hidden"
              name="role_id"
              value={editCompanyData.role_id}
            />
            <FormControl sx={{ marginBottom: "15px" }}>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="name">
              { t('general.name') }
              </InputLabel>
              <OutlinedInput
                id="name"
                sx={{ m: "5px 10px", width: "550px" }}
                name="name"
                value={editCompanyData.name}
                onChange={(e) => onValueChange(e)}
                label="name"
              />
            </FormControl>
            <FormControl sx={{ marginBottom: "15px" }}>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="email">
              { t('general.email') }
              </InputLabel>
              <OutlinedInput
                id="email"
                sx={{ m: "5px 10px", width: "550px" }}
                name="email"
                value={editCompanyData.email}
                onChange={(e) => onValueChange(e)}
                label="email"
              />
            </FormControl>
            {/* <FormControl sx={{ marginBottom: "15px" }}>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="password">
                password
              </InputLabel>
              <OutlinedInput
                type="password"
                id="password"
                sx={{ m: "5px 10px", width: "550px" }}
                name="password"
                value={editCompanyData.password}
                onChange={(e) => onValueChange(e)}
                label="password"
              />
            </FormControl> */}
            <FormControl sx={{ marginBottom: "15px" }}>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="contact">
              { t('general.contact') }
              </InputLabel>
              <OutlinedInput
                id="contact"
                sx={{ m: "5px 10px", width: "550px" }}
                name="contact"
                value={editCompanyData.contact}
                onChange={(e) => onValueChange(e)}
                label="contact"
              />
            </FormControl>
            <FormControl sx={{ marginBottom: "15px" }}>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="address">
              { t('general.address') }
              </InputLabel>
              <OutlinedInput
                id="address"
                sx={{ m: "5px 10px", width: "550px" }}
                name="address"
                value={editCompanyData.address}
                onChange={(e) => onValueChange(e)}
                label="address"
              />
            </FormControl>
            {/* actions Buttons */}
            <Box
              sx={{ mt: 3, mb: 2, display: "flex", justifyContent: "center" }}
            >
              <Button
                onClick={() => nevigate("/dashboard/adminDashboard/companies")}
                variant="contained"
                sx={{ minWidth: "150px", p: 1, m: 1,bgcolor:"#ff9800",":hover":{bgcolor:"#ff9800"}}}
              >
                { t('general.move_back') }
              </Button>
              <Button
                variant="contained"
                sx={{
                  minWidth: "150px",
                  p: 1,
                  m: 1,
                  bgcolor: "#F16101",
                  ":hover": { bgcolor: "#F16101" },
                }}
                onClick={() => updateData()}
              >
                { t('general.update') }
              </Button>
            </Box>
          </Box>
        ) : (
          "Loading..."
          // <CircularProgress />
        )}
      </Container>
    </ThemeProvider>
  );
};

export default EditCompany;
