import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  createTheme,
  FormControl,
  Input,
  InputLabel,
  OutlinedInput,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import { UPDATE_OR_CREATE_EMPLOYEE } from "../../../graphql/mutations";
import { EMPLOYEE_DATA, GET_EMPLOYEE } from "../../../graphql/queries";
import { useTranslation } from "react-i18next";

const theme = createTheme();
const UpdateEmployee = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [t] = useTranslation('global');
  const [editEmployeeData, setEditEmployeeData] = useState({
    name: "",
    email: "",
    contact: "",
    address: "",
    // password: "",
    wage: 0,
    role_id: 4,
  });
  const [updateEmployee, {loading: updateLoading}] = useMutation(UPDATE_OR_CREATE_EMPLOYEE, {
    onCompleted: (() => {
    })
  });
  ///////Get Company Query.....
  const { data, loading } = useQuery(GET_EMPLOYEE, {
    variables: { id: id },
  });
  useEffect(() => {
    try {
      setEditEmployeeData(data.user);
    } catch (error) {
      console.log(error.message);
    }
  }, [data]);

  const onValueChange = (e) => {
    let newData = { ...editEmployeeData };
    let name = e.target.name;
    let val = e.target.value;
    if (
      name === "name" ||
      name === "email" ||
      name === "contact" ||
      name === "wage" ||
      name === "address" ||
      name === "role_id"
    ) {
      newData = { ...newData, [name]: val };
    }
    setEditEmployeeData(newData);
  };

  const updateData = async () => {
    try {
      const response = await updateEmployee({
        variables: {
          id: id,
          name: editEmployeeData.name,
          email: editEmployeeData.email,
          contact: editEmployeeData.contact,
          address: editEmployeeData.address,
          wage: parseFloat(editEmployeeData.wage),
          role_id: parseInt(editEmployeeData.role.id),
        },
      });

      if (response && response.data) {
        setEditEmployeeData("");
        navigate("/dashboard/companyDashboard/employees");
      }
    } catch (error) {
      console.error("Error updating employee:", error);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        {!loading ? (
          <Box
            sx={{
              textAlign: "center",
              boxShadow: 2,
              paddingTop: "35px",
              paddingBottom: "12px",
              marginTop: "1%",
            }}
          >
            <Typography variant="h6" textAlign="center" sx={{ m: 3 }}>
              { t('employees.edit_employee_detail')  }
            </Typography>
            <Input
              type="hidden"
              name="role_id"
              value={editEmployeeData.role_id}
            />
            <FormControl sx={{ marginBottom: "15px" }}>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="name">
              { t('general.name') }
              </InputLabel>
              <OutlinedInput
                id="name"
                sx={{ m: "5px 10px", width: "550px" }}
                name="name"
                value={editEmployeeData.name}
                onChange={(e) => onValueChange(e)}
                label="name"
              />
            </FormControl>
            <FormControl sx={{ marginBottom: "15px" }}>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="email">
              { t('general.email') }
              </InputLabel>
              <OutlinedInput
                id="email"
                sx={{ m: "5px 10px", width: "550px" }}
                name="email"
                value={editEmployeeData.email}
                onChange={(e) => onValueChange(e)}
                label="email"
              />
            </FormControl>
            <FormControl sx={{ marginBottom: "15px" }}>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="wage">
              { t('employees.wage_hr') }
              </InputLabel>
              <OutlinedInput
                id="wage"
                sx={{ m: "5px 10px", width: "550px" }}
                name="wage"
                value={editEmployeeData.wage}
                onChange={(e) => onValueChange(e)}
                label="wage / hr"
              />
            </FormControl>
            <FormControl sx={{ marginBottom: "15px" }}>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="contact">
              { t('general.contact') }
              </InputLabel>
              <OutlinedInput
                id="contact"
                sx={{ m: "5px 10px", width: "550px" }}
                name="contact"
                value={editEmployeeData.contact}
                onChange={(e) => onValueChange(e)}
                label="contact"
              />
            </FormControl>
            <FormControl sx={{ marginBottom: "15px" }}>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="address">
              { t('general.address') }
              </InputLabel>
              <OutlinedInput
                id="address"
                sx={{ m: "5px 10px", width: "550px" }}
                name="address"
                value={editEmployeeData.address}
                onChange={(e) => onValueChange(e)}
                label="address"
              />
            </FormControl>
            {/* actions Buttons */}
            <Box
              sx={{ mt: 3, mb: 2, display: "flex", justifyContent: "center" }}
            >
              <Button
                onClick={() =>
                  navigate("/dashboard/companyDashboard/employees")
                }
                variant="contained"
                sx={{
                  minWidth: "150px",
                  p: 1,
                  m: 1,
                  bgcolor: "#ff9800",
                  ":hover": { bgcolor: "#ff9800" },
                }}
              >
               { t('general.move_back') }
              </Button>
              <Button
                variant="contained"
                sx={{
                  minWidth: "150px",
                  p: 1,
                  m: 1,
                  bgcolor: "#F16101",
                  ":hover": { bgcolor: "#F16101" },
                }}
                onClick={() => updateData()}
                disabled={updateLoading}
              >
                 { updateLoading ?  (
                  <CircularProgress size={24} color="primary" />
                ) : t('general.update') }
              </Button>
            </Box>
          </Box>
        ) : (
          <h3
            style={{
              color: "blue",
              textAlign: "center",
            }}
          >
            Loading...
          </h3>
        )}
      </Container>
    </ThemeProvider>
  );
};
export default UpdateEmployee;
