import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Box, Button } from "@mui/material";
import Grid from '@mui/material/Grid';
import Filter from "../../Filter";
import {
  DataGrid,
  GridToolbar
} from "@mui/x-data-grid";
import { SUMMARY } from "../../../graphql/queries";
import dayjs from 'dayjs';
import ReusableDatePicker from "../../ReusableDatePicker";
import { useTranslation } from "react-i18next";

const SummaryReport = () => {

  const [report, setReport] = useState([]);
  const [pageSize, setPageSize] = useState(8);
  const [selectedOption, setSelectedOption] = useState(null);
  const [resetKey, setResetKey] = useState(0);
  const [fromPickerValue, setFromPickerValue] = useState(null);
  const [toPickerValue, setToPickerValue] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [dateRangeLoading, setDateRangeLoading] = useState(false);
  const {t} = useTranslation('global');

  //reports data
  const { data, loading, refetch } = useQuery(SUMMARY, {
    variables: {
      filter: (selectedOption ? selectedOption.value : null),
      date_from: dateFrom ?? null,
      date_to : dateTo ?? null
    },
    fetchPolicy: "no-cache"
  });

  const options = [
    { label: t('apply_filter.today'), value: "today" },
    { label: t('apply_filter.last_week'), value: "last_week" },
    { label: t('apply_filter.last_month'), value: "last_month" },
    { label: t('apply_filter.last_6_months'), value: "last_6_months" },
    { label: t('apply_filter.last_year'), value: "last_year" },
  ];
  
  const handleSelectionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleReset = () => {
    setDateRangeLoading(true);
    setResetKey(prevKey => prevKey + 1);

  
    // Reset the date picker values to null
    setFromPickerValue(null);
    setToPickerValue(null);
  
    // Reset both dateFrom and dateTo to null
    setDateFrom(null);
    setDateTo(null);
  
    refetch({
      filter: selectedOption ? selectedOption.value : null,
      date_from: null,
      date_to: null,
    }).then(() => {
      setDateRangeLoading(false);
    });
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "customer",
      headerName: t('general.customer'),
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => `${params.row.customer.name}`,
    },
    {
      field: "employee",
      headerName: t('general.employee'),
      flex: 1,
      minWidth: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => `${params.row.employee.name}`,
    },
    {
      field: "total_checkouts",
      headerName: t('summary.total_checkin_checkouts'),
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => `${params.row.total_checkouts}`,
    },
    {
      field: "total_hours_worked",
      headerName: t('general.total_hrs_worked'),
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => {
        let totalHours = params.row.total_hours;
        let totalMinutes = params.row.total_minutes;
      
        if (totalMinutes >= 60) {
          let additionalHours = Math.floor(totalMinutes / 60);
          let remainingMinutes = totalMinutes % 60;
      
          totalHours += additionalHours;
          totalMinutes = remainingMinutes;
        }
      
        return `${totalHours} hrs ${totalMinutes} mins`;
      }
    },
  ];

  useEffect(() => {
    try {
      setReport(data.summary);
    } catch (error) {
      console.log(error.message);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [selectedOption, refetch]);

  return (  
    <>
    <Grid container alignItems="center" sx={{ marginBottom: '20px' }}>
      <Grid item xs={4}>
        <h2>{ t('headings.checkin_checkout_summary') }</h2>
      </Grid>
      <Grid item xs={8} container justifyContent="flex-end">
        {/* date from */}
        <ReusableDatePicker
          key={`fromDatePicker-${resetKey}`} // Use a unique key for each DatePicker
          label= { t('general.from') }
          initialValue={fromPickerValue}
          onDateChange={(newValue) => { 
            setDateRangeLoading(true);
            const formattedDate = newValue ? dayjs(new Date(newValue.toString())).format("YYYY-MM-DD") : null;
            setDateFrom(formattedDate);
            refetch({
              date_from: formattedDate,
              date_to: dateTo,
            }).then(() => {
              setDateRangeLoading(false);
            });
          }}
        />
        {/* date to */}
        <ReusableDatePicker
          key={`toDatePicker-${resetKey}`} 
          label={ t('general.to') }
          initialValue={toPickerValue}
          onDateChange={(newValue) => { 
            setDateRangeLoading(true);
            const formattedDate = newValue ? dayjs(new Date(newValue.toString())).format("YYYY-MM-DD") : null;
            setDateTo(formattedDate);
            refetch({
              date_from: dateFrom,
              date_to: formattedDate,
            }).then(() => {
              setDateRangeLoading(false);
            });
          }}
        />
        <Button variant="contained" color="primary" onClick={handleReset} sx={{marginRight: "40px"}}>
          { t('general.reset') }
        </Button>
        <Filter 
          options={options} 
          onChange={handleSelectionChange} 
        />
      </Grid>
    </Grid>
    <Box
      sx={{
        height: 700,
        width: "100%",
        "& .super-app-theme--header": {
          fontWeight: "bolder",
          fontSize: 18,
        },
      }}
    >
      <DataGrid
        // autoHeight
        loading={dateRangeLoading || loading}
        columns={columns}
        rows={report}
        rowHeight={60}
        pageSize={pageSize}
        rowsPerPageOptions={[8,10,20]}
        getRowId={(row) => row.employee_id + row.customer.id } 
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        slots={{ toolbar: GridToolbar }}
        localeText={{
          toolbarExport: t('data_grid.export'),
          toolbarExportCSV : t('data_grid.toolbar_export_csv'),
          toolbarExportPrint : t('data_grid.toolbar_export_print'),
          toolbarFilters : t('data_grid.toolbar_filters'),
          toolbarColumns : t('data_grid.toolbar_columns'),
          columnMenuSortAsc : t('data_grid.column_menu_sort_asc'),
          columnMenuSortDesc: t('data_grid.column_menu_sort_desc'),
          columnMenuHideColumn : t('data_grid.column_menu_hide_column'),
          columnMenuManageColumns : t('data_grid.column_menu_manage_columns'),
          toolbarDensity: t('data_grid.toolbar_density_label'),
          toolbarDensityLabel: t('data_grid.toolbar_density_label'),
          toolbarDensityCompact: t('data_grid.toolbar_density_compact'),
          toolbarDensityStandard: t('data_grid.toolbar_density_standard'),
          toolbarDensityComfortable: t('data_grid.toolbar_density_comfortable'),
          toolbarQuickFilterPlaceholder : t('data_grid.toolbar_quick_filter_label'),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
          pagination: {
            labelRowsPerPage: t('data_grid.rows_per_page_options'),
            labelDisplayedRows : ({ from, to, count }) => {
                  const countText = count !== -1 ? count : `${t('general.more_than')} ${to}`;
                  return `${from}–${to} ${t('general.of')} ${countText}`;
              } 
          }
        }}
      />
    </Box>
    </>
  );
};

export default SummaryReport;