import { Box, Typography } from "@mui/material";
import React from "react";

const Footer = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        display: "flex",
        py: 2,
        justifyContent: "center",
        alignItems: "center",
        boxShadow: 4,
      }}
    >
      <Typography sx={{ fontSize: 14 }}>
        Powered by{" "}
        <a
          href="https://www.pkteam.com"
          target="_blank"
          style={{ fontSize: 14, color: "#FF6D00", textDecoration: "none" }}
        >
          PkTeam
        </a>{" "}
        | © {currentYear} CICO | All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
