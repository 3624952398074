import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 250, sm: 400, md: 600 },
    bgcolor: "background.paper",
    border: "2px solid #000",
    // borderRadius: "20px",
    boxShadow: 24,
    p: 4,
};

const ApproveCheckout = (props) =>  {
    const {
        open,
        handleClose,
        schedulesData,
        handleChange,
        handleClick,
        approveLoading,
        setLoading
    } = props;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          sx={{ mt: 2, mb: 4 }}
        >
          Select schedule to assign:
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Select schedules
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={handleChange}
          >
            {schedulesData &&
              schedulesData.map((schedule) => {
                const startTime = new Date(schedule.start_time);
                const endTime = new Date(schedule.end_time);

                const options = {
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: true,
                };
                const formattedStartTime = startTime.toLocaleTimeString(
                  undefined,
                  options
                );
                const formattedEndTime = endTime.toLocaleTimeString(
                  undefined,
                  options
                );

                return (
                  <MenuItem key={schedule.id} value={schedule.id}>
                    {`${formattedStartTime} - ${formattedEndTime}`}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <Box sx={{ mt: 3, mb: 2, textAlign: "center" }}>
          <Button
            onClick={() => {
              handleClose();
              setLoading(false);
            }}
            variant="contained"
            sx={{
              minWidth: "150px",
              p: 1,
              m: 1,
              bgcolor: "#ff9800",
              ":hover": { bgcolor: "#ff9800" },
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{
              minWidth: "150px",
              p: 1,
              m: 1,
              bgcolor: "#F16101",
              ":hover": { bgcolor: "#F16101" },
            }}
            variant="contained"
            onClick={handleClick}
            disabled={approveLoading}
          >
            {approveLoading ? "loading..." : "APPROVE"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ApproveCheckout;
