import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { CHECKOUT_PER_CUSTOMER, CHECKOUT_IMAGES, EMPLOYEE_DATA, GET_SCHEDULES_PER_CUSTOMER } from "../../../graphql/queries";
import { SEND_CHECKOUT_DATA, APPROVED_CHECKOUT } from "../../../graphql/mutations";
import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Grid, Divider, CardMedia, Button } from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { MAP_API_KEY } from "../../Authentication/config";
import {
    useJsApiLoader,
    GoogleMap,
    MarkerF
  } from '@react-google-maps/api';
import AdjustCheckout from "./AdjustCheckout";
import ApproveCheckout from "./ApproveCheckout";
import { AUTH_ID } from "../../Authentication/constant";
import dayjs from "dayjs";

const CheckoutDetail = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const { t } = useTranslation('global');
    const [checkout, setCheckout] = useState();
    const [images, setImages] = useState([]);
    const [checkinCenter, setCheckinCenter] = useState({});
    const [checkoutCenter, setCheckoutCenter] = useState({});
    const [empName, setEmpName] = useState([]);
    const [loadings, setLoading] = useState(false);

    // State for adjust checkout modal.
    const [openAdj, setOpenAdj] = useState(false);
    const handleCloseAdj = () => {
        setOpenAdj(false);
    };
    const handleOpenAdj = () => {
        setOpenAdj(true);
    };

    // State for approve checkout modal.
    const [open, setOpen] = useState(false);
    const [schedulesData, setSchedulesData] = useState([]);
    const [schID, setSchID] = useState("");
    const [cusID, setCusID] = useState("");
    const handleClose = () => setOpen(false);

    const [fetchData, { data: schedules }] = useLazyQuery(
      GET_SCHEDULES_PER_CUSTOMER
    );


    const handleOpen = (customerId, rowID) => () => {
      setOpen(true);
      setCusID(rowID);
      fetchData({
        variables: {
          parent_id: AUTH_ID,
          customer_id: customerId,
        },
      });
    };
  

    const handleChange = (event) => {
      setSchID(event.target.value);
    };

    const [ApprovedCheckOUt, { loading: approveLoading }] =
    useMutation(APPROVED_CHECKOUT, {
      onCompleted: () => {
        handleClose();
        navigate("/dashboard/companyDashboard/checkincheckout");
      },
    });

    const handleClick = async () => {
      await ApprovedCheckOUt({
        variables: {
          schedule_id: schID,
          checkout_id: cusID,
        },
      });
    };
    
    useJsApiLoader({
        googleMapsApiKey: MAP_API_KEY,
        libraries: ['places'],
    })

    const { data, loading } = useQuery(CHECKOUT_PER_CUSTOMER, {
       variables: { id: id },
       fetchPolicy:"no-cache"
    });

    const { data: empData } = useQuery(EMPLOYEE_DATA, {
        variables: { parent_id: AUTH_ID },
        fetchPolicy:"no-cache"
      });

    const { data: checkOutImages, loading: imagesLoading } = useQuery(CHECKOUT_IMAGES, {
       variables: { id: id},
       fetchPolicy:"no-cache"
    });

    const [updateAdjustCheckOut] = useMutation(SEND_CHECKOUT_DATA, {
        onCompleted: () => {
          handleCloseAdj();
          setLoading(false);
        },
      });

    useEffect(() => {
      try {
        setCheckout(data.checkOut);
        const location = JSON.parse(checkout?.location);
    
        setCheckinCenter({
            lat : parseFloat(location.checkInLatitude),
            lng: parseFloat(location.checkInLongitude)
        });

        setCheckoutCenter({
            lat : parseFloat(location.checkOutLatitude),
            lng: parseFloat(location.checkOutLongitude)
        });

      } catch (error) {
        console.log(error.message);
      }
    }, [checkout?.location, data]);

    useEffect(() => {
        try {
          setEmpName(empData.employees);
        } catch (error) {
          console.log(error.message);
        }
    }, [data, empData]);

    useEffect(() => {
        try {
            console.log("inside iamges");
            setImages(checkOutImages.checkOutImages.images);
        } catch (error) {
            console.log("Error:", error.message);
        }
    }, [data, checkOutImages]);

    useEffect(() => {
      try {
        setSchedulesData(schedules.schedules);
      } catch (error) {
        console.log(error.message);
      }
    }, [schedules]);

    const handleInputChange = (dateName, dateValue) => {
        
        if (dateName === "startTime") {
            setCheckout({
            ...checkout,
            start_time: dayjs(new Date(dateValue.toString())).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
          });
        } else if (dateName === "endTime") {
            setCheckout({
            ...checkout,
            end_time: dayjs(new Date(dateValue.toString())).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
          });
        } else if (dateName === "employee") {
            setCheckout({
            ...checkout,
            employee: {
              ...checkout.employee,
              id: dateValue.target.value,
            },
          });
        }
    };

    // save the adjust checkout data
    const handleSaveAdjustClick = async () => {
        setLoading(true);
        await updateAdjustCheckOut({
          variables: {
            id: checkout?.id,
            start_time: checkout?.start_time,
            end_time: checkout.end_time,
            employee_id: checkout.employee.id,
            customer_id: checkout.customer.id,
            company_id: AUTH_ID,
          },
        });
      };

    // Function to format time
    const formatTime = (timeString) => {
        const options = { hour: "numeric", minute: "numeric", hour12: true };
        return new Date(timeString).toLocaleTimeString(undefined, options);
    };

    const formatDateTime = (dateTimeString) => {
      const inputTime = new Date(dateTimeString);
      const formattedTime = inputTime.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
  
      return formattedTime;
    };

  return (
    <>
      <Paper elevation={3} sx={{ padding: 2 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h4" gutterBottom>
            { t('headings.checkout_details') }
            </Typography>

            {/* Render this section in case of pending checkouts */}
            {checkout && checkout.schedule === null && (
                <div>
                    <Button variant="contained"
                     style={{
                        marginBottom: "5px",
                        marginRight: "5px"
                      }}
                      onClick={handleOpen(
                        checkout.customer.id,
                        checkout.id
                      )}
                    >
                        {t('checkouts.approve_checkout')}
                    </Button>
                    <Button variant="contained"
                      style={{
                        backgroundColor: "#ff6d00",
                        marginBottom: "5px",
                      }}
                      onClick={() => handleOpenAdj()}
                    >
                        {t('checkouts.adjust_checkout')}
                    </Button>
                </div>
            )}
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">
              {t('general.company') } {t('general.name') }: {loading ? ( t('general.loading') ) : (checkout?.company.name)}
            </Typography>
            <Typography variant="subtitle1">
            {t('general.customer') } {t('general.name') }: { loading ? ( t('general.loading') ) : (checkout?.customer.name) }
            </Typography>
            <Typography variant="subtitle1">
            {t('general.employee') } {t('general.name') }: {loading ? ( t('general.loading') ) : (checkout?.employee.name)}
            </Typography>
            <Typography variant="subtitle1">
                {t('reports.schedule_time')}: {loading ? t("general.loading") : (
                    checkout?.schedule ?
                    `${formatTime(checkout.schedule.start_time)} - ${formatTime(checkout.schedule.end_time)}`
                    : t('schedules.not_assigned')
                )}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">
                {t('general.checkout')} {t('general.start_time')}:{" "}
              {loading ? t("general.loading") : formatTime(checkout?.start_time)}
            </Typography>
            <Typography variant="subtitle1">
            {t('general.checkout')} {t('general.end_time')}:{" "}
              {loading ? t("general.loading") : formatTime(checkout?.end_time)}
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: '16px 0' }} />

        <Typography variant="h5" gutterBottom>
          {t('headings.checkout_images')}
        </Typography>

        <Grid container spacing={2}>
            {imagesLoading ? (
                 <CircularProgress color="primary" size={50} sx={{ mt: 5, ml:5 }} />
            ) : (
                images.length > 0 ? (images.map((image, index) => (
                  <Grid item xs={12} sm={4} key={index}>
                  <div style={{ textAlign: 'center' }}>
                    <CardMedia
                      component="img"
                      alt={`Checkout Image ${index + 1}`}
                      style={{ maxHeight: '500px', width: '100%', objectFit: 'contain' }}
                      image={`data:image/jpeg;base64,${image.image}`}
                    />
                    <Typography gutterBottom component="div" sx={{ mt: 2 }}>
                      {image.creation_time && (
                        <>
                          {`${t('general.captured_at')}: ${formatDateTime(image.creation_time)}`}
                          <br />
                        </>
                      )}
                      {image.uploaded_time && (
                        <>
                          {`${t('general.uploaded_at')}: ${formatDateTime(image.uploaded_time)}`}
                          <br />
                        </>
                      )}
                      {image.uploaded_from && `${t('general.uploaded_from')}: ${image.uploaded_from}`}
                    </Typography>
                  </div>
                 </Grid>                
                ))
                ) :  
                (
                    <Typography variant="h6" sx={{ m:3 }}>
                        {t('general.no_image')}!
                    </Typography>
                )
            )}
        </Grid>


        <Divider sx={{ margin: '16px 0' }} />

        <Typography variant="h5" gutterBottom>
            {t('checkouts.locations')}
        </Typography>

        <Grid container spacing={2}>
            {/* Check in location */}
            <Grid item xs={12} sm={12} md={6}>
                <Typography variant="h6" gutterBottom>
                    {t('checkouts.checkin_location')}
                </Typography>
                {(checkinCenter?.lat) ? (
                    <GoogleMap
                        center={checkinCenter}
                        zoom={15}
                        mapContainerStyle={{ width: '100%', height: '500px' }}
                        // options={{
                        //     zoomControl: false,
                        //     streetViewControl: false,
                        //     mapTypeControl: false,
                        //     fullscreenControl: false,
                        // }}
                    >
                        <MarkerF 
                        showInfoWindow={true}
                        position={checkinCenter} />
                    </GoogleMap>
                ) : (
                    <CircularProgress color="primary" size={50} sx={{ mt: 5 }} />
                )}
            </Grid>

            {/* Checkout location */}
            <Grid item xs={12} sm={12} md={6}>
                <Typography variant="h6" gutterBottom>
                    {t('checkouts.checkout_location')}
                </Typography>
                {(checkoutCenter?.lat) ? (
                    <GoogleMap
                        center={checkoutCenter}
                        zoom={15}
                        mapContainerStyle={{ width: '100%', height: '500px' }}
                    >
                        <MarkerF position={checkoutCenter} />
                    </GoogleMap>
                ) : (
                    <CircularProgress color="primary" size={50} sx={{ mt: 5 }} />
                )}
            </Grid>
        </Grid>
      </Paper>

      {/* Approve checkout */}
      <ApproveCheckout
        open={open}
        handleClose={handleClose}
        schedulesData={schedulesData}
        handleChange={handleChange}
        handleClick={handleClick}
        approveLoading={approveLoading}
        setLoading={setLoading}
      />

      {/* Adjust checkout */}
      <AdjustCheckout 
        open={openAdj}
        onClose={() => setOpenAdj(false)}
        checkOut={checkout}
        empName={empName}
        handleInputChange={handleInputChange}
        handleSaveAdjustClick={handleSaveAdjustClick}
        loadings={loadings}
        AdjLoading={false}
      />
    </>
  );
};

export default CheckoutDetail;
