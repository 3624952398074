import { Avatar, MenuItem } from "@mui/material";
import React from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const [profile, setProfile] = useState();
  const {t} = useTranslation("global");

  const handleProfile = () => {
    if (localStorage.getItem("role_id") === "1") {
      const profiles = [
        {
          path: "/dashboard/adminDashboard/profile",
        },
      ];
      setProfile(profiles);
    } else {
      const profiles = [
        {
          path: "/dashboard/companyDashboard/profile",
        },
      ];
      setProfile(profiles);
    }
  };

  useLayoutEffect(() => {
    handleProfile();
  }, []);
  return (
    <React.Fragment>
      {profile
        ? profile.map((prof, index) => {
            return (
              <Link
                key={index}
                to={prof.path}
                style={{ textDecoration: "none", color: "black" }}
              >
                <MenuItem>
                  <Avatar />  { t('general.profile') }
                </MenuItem>
              </Link>
            );
          })
        : ""}
    </React.Fragment>
  );
};

export default Profile;
