// NavRoutes.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import SignIn from "../components/Authentication/SignIn";
import Dashboard from "../components/newLayout/Dashboard";
import ProtectRoutes from "../components/newLayout/protectedRoutes/ProtectRoutes";
import NotFound from "../components/Authentication/NotFound";
import ForgotPassword from "../components/Authentication/ForgotPassword";
import ResetPassword from "../components/Authentication/ResetPassword";

const NavRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="dashboard/*"
        element={
          <ProtectRoutes>
            <Dashboard />
          </ProtectRoutes>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default NavRoutes;
