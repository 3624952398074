import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  createTheme,
  FormControl,
  InputLabel,
  OutlinedInput,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import { UPDATE_OR_CREATE_CUSTOMER } from "../../../graphql/mutations";
import { GET_CUSTOMER } from "../../../graphql/queries";
import { useTranslation } from "react-i18next";

const theme = createTheme();
const EditCompany = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {t} = useTranslation('global');
  const [editCustomerData, setEditCustomerData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    paid_per_hr: ""
  });
  const [updateCustomer, {loading: updateLoading}] = useMutation(UPDATE_OR_CREATE_CUSTOMER, {
    onCompleted: (() => {
      // navigate("/dashboard/companyDashboard/customers");
    })
  });
  ///////Get Company Query.....
  const { data, loading } = useQuery(GET_CUSTOMER, {
    variables: { id: id },
  });

  useEffect(() => {
    try {
      setEditCustomerData(data.customer);
    } catch (error) {
      console.log(error.message);
    }
  }, [data]);

  const onValueChange = (e) => {
    let newData = { ...editCustomerData };
    let name = e.target.name;
    let val = e.target.value;
    if (
      name === "name" ||
      name === "email" ||
      name === "paid_per_hr" ||
      name === "phone" ||
      name === "address" 
    ) {
      newData = { ...newData, [name]: val };
    }
    setEditCustomerData(newData);
  };

  const updateData = async () => {
    try {
      const response = await updateCustomer({
        variables: {
          id: id,
          name: editCustomerData.name,
          email: editCustomerData.email,
          paid_per_hr: parseFloat(editCustomerData.paid_per_hr),
          phone: editCustomerData.phone,
          address: editCustomerData.address,
        },
      });
    
      if (response && response.data) {
        navigate("/dashboard/companyDashboard/customers");
      }
    } catch (error) {
      console.error("Error updating customer:", error);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        {!loading ? (
          <Box
            sx={{
              textAlign: "center",
              boxShadow: 2,
              paddingTop: "35px",
              paddingBottom: "12px",
              marginTop: "8%",
            }}
          >
            <Typography variant="h6" textAlign="center" sx={{ m: 3 }}>
              { t('customers.edit_customer_details') }
            </Typography>
            <FormControl sx={{ marginBottom: "15px" }}>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="name">
              { t('general.name') }
              </InputLabel>
              <OutlinedInput
                id="name"
                sx={{ m: "5px 10px", width: "550px" }}
                name="name"
                value={editCustomerData?.name ? editCustomerData.name : ""}
                onChange={(e) => onValueChange(e)}
                label="name"
              />
            </FormControl>
            <FormControl sx={{ marginBottom: "15px" }}>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="email">
              { t('general.email') }
              </InputLabel>
              <OutlinedInput
                id="email"
                sx={{ m: "5px 10px", width: "550px" }}
                name="email"
                value={editCustomerData?.email ? editCustomerData.email : ""}
                onChange={(e) => onValueChange(e)}
                label="email"
              />
            </FormControl>
            <FormControl sx={{ marginBottom: "15px" }}>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="paid">
              { t('customers.paid_hr') }
              </InputLabel>
              <OutlinedInput
                type="paid_per_hr"
                id="paid_per_hr"
                sx={{ m: "5px 10px", width: "550px" }}
                name="paid_per_hr"
                value={editCustomerData?.paid_per_hr}
                onChange={(e) => onValueChange(e)}
                label="paid_per_hr"
              />
            </FormControl>
            <FormControl sx={{ marginBottom: "15px" }}>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="phone">
              { t('general.contact') }
              </InputLabel>
              <OutlinedInput
                fullWidth
                id="phone"
                sx={{ m: "5px 10px", width: "550px" }}
                name="phone"
                value={editCustomerData?.phone ? editCustomerData.phone : ""}
                onChange={(e) => onValueChange(e)}
                label="phone"
              />
            </FormControl>
            <FormControl sx={{ marginBottom: "15px" }}>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="address">
              { t('general.address') }
              </InputLabel>
              <OutlinedInput
                fullWidth
                id="address"
                sx={{ m: "5px 10px", width: "550px" }}
                name="address"
                value={editCustomerData?.address ? editCustomerData.address : ""}
                onChange={(e) => onValueChange(e)}
                label="address"
              />
            </FormControl>
            {/* actions Buttons */}
            <Box
              sx={{ mt: 3, mb: 2, display: "flex", justifyContent: "center" }}
            >
              <Button
                onClick={() =>
                  navigate("/dashboard/companyDashboard/customers")
                }
                variant="contained"
                sx={{
                  minWidth: "150px",
                  p: 1,
                  m: 1,
                  bgcolor: "#ff9800",
                  ":hover": { bgcolor: "#ff9800" },
                }}
              >
                { t('general.move_back') }
              </Button>
              <Button
                sx={{
                  minWidth: "150px",
                  p: 1,
                  m: 1,
                  bgcolor: "#F16101",
                  ":hover": { bgcolor: "#F16101" },
                }}
                variant="contained"
                onClick={() => updateData()}
                disabled={updateLoading}
              >
                { updateLoading ?  (
                  <CircularProgress size={24} color="primary" />
                ) : t('general.update') }
              </Button>
            </Box>
          </Box>
        ) : (
          <h3
            style={{
              color: "blue",
              textAlign: "center",
            }}
          >
            Loading...
          </h3>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default EditCompany;
