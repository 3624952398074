import {React} from "react";
import Typography from "@mui/material/Typography";
import {
    Box,
    Modal,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Grid,
    TextField
  } from "@mui/material";
  
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 250, sm: 400, md: 600 },
  bgcolor: "background.paper",
  border: "2px solid #000",
  // borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

const AdjustCheckout = (props) => {

    console.log(props);

    const {
        open,
        onClose,
        checkOut,
        empName,
        handleInputChange,
        handleSaveAdjustClick,
        loadings,
        AdjLoading
      } = props;

    const {t} = useTranslation('global');

    return (
    <>
        {/* Adjust Checkout modal */}
        <Modal open={open} onClose={onClose}>
            <Box sx={style}>
                {!AdjLoading ? (
                <>
                    {" "}
                    <Typography
                    id="modal-modal-title"
                    variant="h5"
                    component="h2"
                    sx={{ mt: 2, mb: 4 }}
                    >
                    Adjust Checkout
                    </Typography>
                    <Grid container sx={{ justifyContent: "center", gap: 2 }}>
                    <Grid item xs={10.4}>
                        <FormControl fullWidth>
                        <InputLabel id="schedule-employee-name">
                            Select Employees
                        </InputLabel>
                        <Select
                            labelId="schedule-employee-name"
                            id="schedule-employee"
                            label="Employee Name"
                            required
                            name="employee"
                            value={checkOut?.employee?.id || ''}
                            onChange={(date) => handleInputChange("employee", date)}
                        >
                            {empName?.map((item) => {
                            return (
                                <MenuItem key={item.id} value={item.id}>
                                {item.name}
                                </MenuItem>
                            );
                            })}
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={10.4} sm={5}>
                        <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label="From"
                            value={checkOut?.start_time}
                            name="startTime"
                            onChange={(date) => handleInputChange("startTime", date)}
                            renderInput={(params) => (
                            <TextField fullWidth {...params} />
                            )}
                        />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={10.4} sm={5}>
                        <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label="To"
                            value={checkOut?.end_time}
                            name="endTime"
                            onChange={(date) => handleInputChange("endTime", date)}
                            renderInput={(params) => (
                            <TextField fullWidth {...params} />
                            )}
                        />
                        </LocalizationProvider>
                    </Grid>
                    <Box
                        sx={{
                        width: "100%",
                        display: { xs: "column", sm: "flex" },
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        }}
                    >
                        <Button
                        variant="contained"
                        sx={{
                            minWidth: 120,
                            bgcolor: "#ff9800",
                            ":hover": { bgcolor: "#ff9800" },
                        }}
                        onClick={onClose}
                        >
                        { t('general.cancel') }
                        </Button>
                        <Button
                        variant="contained"
                        sx={{
                            minWidth: 120,
                            bgcolor: "#F16101",
                            ":hover": { bgcolor: "#F16101" },
                        }}
                        onClick={handleSaveAdjustClick}
                        disabled={loadings}
                        >
                        {loadings ? "loading..." : "Save"}
                        </Button>
                    </Box>
                    </Grid>
                </>
                ) : (
                <h3 style={{ textAlign: "center", color: "blue" }}>Loading...</h3>
                )}
            </Box>
        </Modal>
    </>
);

};

export default AdjustCheckout;