import { useQuery } from "@apollo/client";
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Avatar, CardActionArea, } from "@mui/material";
import { PROFILE_DATA } from "../../../graphql/queries";
import ProfImage from "../../images/profileimage.jpg";
import { Box } from "@mui/system";
import { useState } from "react";
import { useEffect } from "react";

const AdminProfile = () => {
  const [user, setUser] = useState();
  const { data } = useQuery(PROFILE_DATA);

  useEffect(() => {
    try {
      console.log(data.me);
      setUser(data.me);
    } catch (error) {
      console.log(error.message);
    }
  }, [data]);

  return (
    <Box
      display="flex"
      justifyContent={"center"}
      gap={3}
      padding={4}
      width="100%"
    >
      <Card
        sx={{
          width: {
            xs: 500,
            sm: 600,
            md: 900,
          },
          paddingBottom:2,
        }}
      >
        <CardActionArea sx={{ display: "flex", justifyContent: "center" }}>
          <Avatar src={ProfImage} sx={{ width: 300, height: 300 }} />
        </CardActionArea>
        {user ? (
          <CardContent sx={{ paddingX: 5 }}>
            <Typography sx={{ width: "100%" }} variant="h5" marginBottom={2}>
              {user ? user.role.name.toUpperCase() : ""}
            </Typography>
            <Box display="block">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "8px",
                 
                }}
              >
                <Typography
                  width="60%"
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: 1,
                    fontSize: 18,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    Name:
                  </Typography>
                  {user ? user.name : ""}
                </Typography>
                <Typography
                  width="60%"
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: 1,
                    fontSize: 18,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    Address:
                  </Typography>
                  {user ? user.address : ""}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "8px",
                }}
              >
                <Typography
                  width="60%"
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: 1,
                    textAlign: "start",
                    fontSize: 18,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    Email:
                  </Typography>
                  {user ? user.email : ""}
                </Typography>
                <Typography
                  width="60%"
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: 1,
                    fontSize: 18,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    Contact:
                  </Typography>
                  {user ? user.contact : ""}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        ) : (
          <Typography
            sx={{
              marginTop: 3,
              textAlign: "center",
              fontSize: 24,
              fontWeight: "large",
              color: "#4793F7",
            }}
          >
            Loading...
          </Typography>
        )}
        {/* <CardActions
          sx={{ display: "flex", justifyContent: "center", gap: 2, marginY: 5 }}
        >
          <Button size="large" sx={{ fontSize: 18 }} color="primary">
            Profile
          </Button>
          <Button size="large" sx={{ fontSize: 18 }} color="primary">
            Profile
          </Button>
        </CardActions> */}
      </Card>
    </Box>
  );
};

export default AdminProfile;
