import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option) => option.label,
});

export default function Filter({ options, onChange }) {
  const [t, i18n] = useTranslation('global');
  const handleOnChange = (event, newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <Autocomplete
      id="filter-demo"
      options={options}
      getOptionLabel={(option) => option.label}
      filterOptions={filterOptions}
      sx={{ width: 300 }}
      onChange={handleOnChange} 
      renderInput={(params) => <TextField {...params} label={ t('apply_filter.apply_filter') } />}
    />
  );
}
