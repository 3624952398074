import React, { useState, useEffect } from "react";
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SEND_QR_IMAGE } from '../graphql/mutations';
import { useMutation } from '@apollo/client';
import { useTranslation } from "react-i18next";
import jsPDF from 'jspdf';


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


const DownloadMenu = ({ qrCodeRef, fileName, dataUrl }) =>  {
  const [t] = useTranslation('global');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [preparingToDownload, setPreparingToDownload] = useState(false);
  const open = Boolean(anchorEl);
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    // Create an Image element
    const img = new Image();

    img.onload = () => {
      setImageSrc(img);
    };

    // Set the Image source to the Data URL
    img.src = dataUrl;
  }, [dataUrl]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [SendQRImage, { loading : qrLoading }] = useMutation(SEND_QR_IMAGE);

  const handleSendQRImage = async (imageBlob, company) => {
    const variables = {
      image: imageBlob,
      companyName: company,
    };

    try {
      const response = await SendQRImage({
        variables: variables,
      });

      if (response.data.sendQRImage) {
        setPreparingToDownload(true);
        const pdfData = response.data.sendQRImage.pdf;
        
        // Convert base64 data to binary
        const binaryPdfData = atob(pdfData);
    
        // Create a Uint8Array from the binary data
        const uint8Array = new Uint8Array(binaryPdfData.length);
        for (let i = 0; i < binaryPdfData.length; i++) {
          uint8Array[i] = binaryPdfData.charCodeAt(i);
        }
        
        // Create a Blob from the Uint8Array
        const pdfBlob = new Blob([uint8Array], { type: 'application/pdf' });
        
        // Create a download link for the PDF
        const downloadLink = URL.createObjectURL(pdfBlob);
        const anchor = document.createElement('a');
        anchor.href = downloadLink;
        anchor.download = `${company}.pdf`;
        anchor.click();
        
        // Clean up after download
        URL.revokeObjectURL(downloadLink);
        setPreparingToDownload(false);
      }

    } catch (error) {
      console.error('Error uploading image:', error.message);
    }
  };

  const handleDownload = (format) => {
    if (!imageSrc) {
      return;
    }
  
    const canvas = document.createElement('canvas');
    canvas.width = imageSrc.width;
    canvas.height = imageSrc.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(imageSrc, 0, 0);
  
    if (format === 'pdf') {
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: [canvas.width, canvas.height],
      });
  
      const dataUrl = canvas.toDataURL('image/jpeg');
      pdf.addImage(dataUrl, 'JPEG', 0, 0, canvas.width, canvas.height);
      pdf.save(`${fileName}.pdf`);
    } else if (format === 'svg') {
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      svg.setAttribute('width', canvas.width.toString());
      svg.setAttribute('height', canvas.height.toString());
  
      const svgImage = document.createElementNS('http://www.w3.org/2000/svg', 'image');
      svgImage.setAttribute('x', '0');
      svgImage.setAttribute('y', '0');
      svgImage.setAttribute('width', canvas.width.toString());
      svgImage.setAttribute('height', canvas.height.toString());
      svgImage.setAttributeNS('http://www.w3.org/1999/xlink', 'href', canvas.toDataURL('image/png'));
      svg.appendChild(svgImage);
  
      const svgData = new XMLSerializer().serializeToString(svg);
      const blob = new Blob([svgData], { type: 'image/svg+xml' });
      const url = URL.createObjectURL(blob);
  
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}.svg`;
      a.click();
    } else {
      const mimeType = `image/${format}`;
      const dataUrl = canvas.toDataURL(mimeType);
  
      const a = document.createElement('a');
      a.href = dataUrl;
      a.download = `${fileName}.${format}`;
      a.click();
    }
  };
  

  // const handleDownload = (format) => {
  //   const imageElement = qrCodeRef.current;

  //   if (format === "svg") {
  //    // Get the SVG element
  //    const svgElement = imageElement.querySelector("svg");

  //    // Create a new SVG element with desired dimensions (width=250px, height=250px)
  //    const newSvgElement = document.createElementNS(
  //      "http://www.w3.org/2000/svg",
  //      "svg"
  //    );
  //    newSvgElement.setAttribute("width", "1000");
  //    newSvgElement.setAttribute("height", "1000");
 
  //    // Calculate the scale factor and position to center the QR code
  //    const scaleFactor =
  //      1000 /
  //      Math.max(
  //        svgElement.getAttribute("width"),
  //        svgElement.getAttribute("height")
  //      );
  //    const xOffset = (1000 - svgElement.getAttribute("width") * scaleFactor) / 2;
  //    const yOffset = (1000 - svgElement.getAttribute("height") * scaleFactor) / 2;
 
  //    // Clone the original QR code contents to the new SVG element and scale as needed
  //    const clonedContents = svgElement.cloneNode(true);
  //    clonedContents.setAttribute(
  //      "transform",
  //      `scale(${scaleFactor}) translate(${xOffset}, ${yOffset})`
  //    );
  //    newSvgElement.appendChild(clonedContents);
 
  //    // Create a new Blob from the new SVG element
  //    const svgBlob = new Blob(
  //      [new XMLSerializer().serializeToString(newSvgElement)],
  //      { type: "image/svg+xml" }
  //    );
 
  //    // Create a temporary anchor element to trigger the download
  //    const downloadLink = document.createElement("a");
  //    downloadLink.href = URL.createObjectURL(svgBlob);
  //    downloadLink.download = `${fileName}.svg`;
 
  //    // Append the anchor to the document and trigger the download
  //    document.body.appendChild(downloadLink);
  //    downloadLink.click();
 
  //    // Remove the temporary anchor
  //    document.body.removeChild(downloadLink);
  //   } else if (format === "png" || format === "jpg" || format === "jpeg") {

  //       // Get the SVG element
  //       const svgElement = imageElement.querySelector("svg");

  //       // Create a canvas element
  //       const canvas = document.createElement("canvas");
  //       // canvas.width = svgElement.getAttribute("width");
  //       // canvas.height = svgElement.getAttribute("height");
  //       canvas.width = 500;
  //       canvas.height = 500;
  //       const context = canvas.getContext("2d");

  //       // Create an image element and set the SVG source as data URI
  //       const svgXml = new XMLSerializer().serializeToString(svgElement);
  //       const image = new Image();
  //       image.src = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svgXml);

  //       // Wait for the image to load
  //       image.onload = () => {
  //       // Draw the image onto the canvas
  //       context.drawImage(image, 0, 0, 500, 500);

  //       // Convert canvas to PNG image data
  //       const pngDataUrl = canvas.toDataURL(`image/${format}`);

  //       // Create a temporary anchor element to trigger the download
  //       const downloadLink = document.createElement("a");
  //       downloadLink.href = pngDataUrl;
  //       downloadLink.download = `${fileName}.${format}`;

  //       // Append the anchor to the document and trigger the download
  //       document.body.appendChild(downloadLink);
  //       downloadLink.click();

  //       // Clean up
  //       document.body.removeChild(downloadLink);
  //       };
  //   }else if(format === "pdf"){
        

  //     const svgElement = imageElement.querySelector("svg");

  //         // Create a canvas element
  //         const canvas = document.createElement("canvas");
  //         canvas.width = 500;
  //         canvas.height = 500;
  //         const context = canvas.getContext("2d");

  //         // Create an image element and set the SVG source as data URI
  //         const svgXml = new XMLSerializer().serializeToString(svgElement);
  //         const image = new Image();
  //         image.src = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svgXml);

  //         // Wait for the image to load
  //         image.onload = async () => {
  //           // Draw the image onto the canvas
  //           context.drawImage(image, 0, 0, 500, 500);

  //           // Convert canvas to Blob (image file)
  //           canvas.toBlob(blob => {
  //             // Now that the image is drawn onto the canvas, call your handling function
  //             const companyName = fileName;
  //             handleSendQRImage(blob, companyName);
  //           }, 'image/png');
  //         };

  //         // Append the image to the DOM to trigger the onload event
  //         // document.body.appendChild(image);

  //   }
  // };

  return (
    <div style={{ textAlign: 'center', marginTop: "20px"  }}>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
       {
          qrLoading ? t('general.downloading') :
          preparingToDownload ? "Preparing to download" : t('general.download')
        }
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
            handleClose();
            handleDownload("svg");
            }} disableRipple>
                SVG
        </MenuItem>
        <MenuItem onClick={() => {
            handleClose();
            handleDownload("png");
            }} disableRipple>
                PNG
        </MenuItem>
        <MenuItem onClick={() => {
            handleClose();
            handleDownload("jpg");
            }} disableRipple>
                 JPG
        </MenuItem>
        <MenuItem onClick={() => {
            handleClose();
            handleDownload("pdf");
            }} disableRipple>
                 PDF
        </MenuItem>
      </StyledMenu>
    </div>
  );
};

export default DownloadMenu;
