import { BrowserRouter } from "react-router-dom";
import NavRoutes from "./navRoutes/NavRoutes";

function App() {
  return (
    <>
      <BrowserRouter>
        <NavRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
