import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import Footer from "../border/Footer";
import Header from "../border/Header";
import { useMutation } from "@apollo/client";
import {FORGET_PASSWORD} from "../../graphql/mutations";
import { FRONT_END_URL } from "./config";
import { Link } from "react-router-dom";


const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [forgetPassword] = useMutation(FORGET_PASSWORD);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
  
    setLoading(true); // Start loading while the mutation is in progress
  
    try {
      await forgetPassword({
        variables: {
          email: email,
          reset_password_url: {
            url: url,
          } 
        },
      });
  
      setSuccessMessage("Password reset instructions sent to your email.");
      setErrorMessage("");
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage("Failed to reset password. Please try again.");
    }
  
    setLoading(false); // Stop loading after the mutation is completed
  };

  useEffect(() => {
    setUrl(`${FRONT_END_URL}reset-password?email=__EMAIL__&token=__TOKEN__`);
  },[email, url]);

  return (
    <Box
      height="100vh"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "space-between",
      }}
    >
      {/* Header Component */}
      <Box>
        <Header />
      </Box>
      
      <Box
        width="100%"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Box
          component="form"
          onSubmit={handleResetPassword}
          sx={{
            width: { xs: "95%", sm: 450 },
            boxShadow: 2,
            borderRadius: 3,
            px: 3,
            py: 4,
          }}
        >
          <Typography
            sx={{
              flexGrow: 1,
              color: "#FF6D00",
              textAlign: "center",
              fontSize: 20,
              fontWeight: "600",
            }}
          >
            FORGOT PASSWORD
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", marginTop: 3 }}>
            <TextField
              sx={{ bgcolor: "#E8F0FE" }}
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={handleEmailChange}
              inputProps={{ style: { fontSize: 17 } }}
            />
            {successMessage && (
              <Alert severity="success" style={{ marginTop:10 }}>{successMessage}</Alert>
            )}
            {errorMessage && <Alert severity="error" style={{ marginTop:10 }}>{errorMessage}</Alert>}
          </Box>

          <Box marginTop={5} display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "#ff6d00",
                width: 160,
                marginBottom: "5px",
                fontSize: 17,
                fontWeight: "600",
              }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress color="inherit" size={32} />
              ) : (
                "Reset Password"
              )}
            </Button>
          </Box>

           {/* Login Link */}
          <Typography
            variant="body2"
            sx={{
              display: "flex",
              justifyContent: "flex-end", 
              marginTop: 2,
            }}
          >
            <Link to="/"
              style={{
                // textDecoration: "none",
                color: "#00796b",
                fontSize: 18,
              }}
            >Back to login</Link>
          </Typography>
        </Box>
      </Box>
      
      {/* Footer Component */}
      <Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default ForgotPassword;
